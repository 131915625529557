import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {AppErrorHandler} from "../../helpers/app.errorhandler";
import {EncryptionService} from "../../helpers/encryption.service";
import {AUTH_HEADER} from "../../model/app.constants";
import {map} from "rxjs/operators";
import {throwError} from "rxjs/internal/observable/throwError";
import {isValueEmpty} from "../../helpers/common.validator";
import {UrlService} from "../../system/url.service";


@Injectable()
export class SearchService {
  constructor(private systemService : UrlService, private http: HttpClient, protected router : Router, private errorHandler : AppErrorHandler, private encryption : EncryptionService){

  }

  public search(firstName : string, lastName : string, dob : string) {
    let url = this.systemService.beneficiaryBaseUrl;
    if(!isValueEmpty(firstName)){
      url = url + '?firstName=' + firstName;
    } else {
      url = url + '?firstName=' + '';
    }
    if(!isValueEmpty(lastName)){
      url = url + '&lastName=' + lastName;
    } else {
      url = url + '&lastName=' + '';
    }
    url = url + '&dob=' + dob;
    return this.http.get(url, {headers: new HttpHeaders({ 'Content-Type': 'application/json'}).append('Access-Control-Expose-Headers', AUTH_HEADER),
      observe: 'response' }).pipe(map((response:any) => {
      if(response){
        if(response.status != 200){
          throwError(response['message']);
        }
        return response;
      }
    }));
  }


}

import {Address, AhcFormControl, Beneficiary} from "../../model/app.model";
import {deformatPhone, getISoDateString} from "../../helpers/common.function";
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";
import {checkLimit, isEmpty, isNumber, isValueEmpty} from "../../helpers/common.validator";
import {OnDestroy, OnInit} from "@angular/core";
import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";
import {Subscription} from "rxjs";

export abstract class BaseBeneficiary implements OnInit, OnDestroy{

  protected beneficiary : Beneficiary;
  protected beneficiaryForm : FormGroup;
  protected required  : Map<String, AbstractControl> =  new Map();
  protected currentYear = new Date().getFullYear();
  protected monthMessage = "Month must be between 1 and 12";
  protected dayMessage = "Day must be between 1 and 31";
  protected yearMessage = "Year must be greater than 1900 and less than " + this.currentYear;
  protected alphaNumberic = /^[A-Z0-9]+$/;
  protected hasError : boolean = false;
  protected error : string;
  protected submitted : boolean = false;
  protected subscription : Subscription;

  protected constructor(protected builder : FormBuilder, protected beneDataService : BeneficiaryDataService){
    if(this.beneDataService){
      this.beneficiary = this.beneDataService.getSelectedBeneficiary();
    }
    if(!this.beneficiary){
      this.beneficiary = new Beneficiary();
      this.beneficiary.address = new Address();
    }
    this.createBeneficiaryForm();
  }

  abstract createBeneficiaryForm();

  protected resetRequiredMap(){
    this.required.set("firstName", this.firstName);
    this.required.set("lastName", this.lastName);
    this.required.set("month", this.month);
    this.required.set("day", this.day);
    this.required.set("year", this.year);
    this.required.set("addrLine1", this.addrLine1);
    this.required.set("city", this.city);
    this.required.set("state", this.addrState);
    this.required.set("zip", this.zip);
    this.required.set("primaryPhone", this.primaryPhone);
    this.required.set("primaryPhoneType", this.primaryPhoneType);
    this.required.set("secondaryPhone", this.secondaryPhone);
    this.required.set("secondaryPhoneType", this.secondaryPhonetype);
    this.required.set("hicn", this.hicn);
    this.required.set("hicnMonth", this.hicnMonth);
    this.required.set("hicnDay", this.hicnDay);
    this.required.set("hicnYear", this.hicnYear);
    this.required.set("medicaidId", this.medicaidId);
    this.required.set("medicaidMonth", this.medicaidMonth);
    this.required.set("medicaidDay", this.medicaidDay);
    this.required.set("medicaidYear", this.medicaidYear);
    this.required.set("medicareId", this.medicareId);
    this.required.set("medicareMonth", this.medicareMonth);
    this.required.set("medicareDay", this.medicareDay);
    this.required.set("medicareYear", this.medicareYear);
    this.required.set("email", this.email);
  }

  protected reset() : void {
    this.hasError = false;
    this.error = undefined;
    this.submitted = false;
  }
  protected createBeneficiary() : Beneficiary {
    let data = this.beneficiaryForm.value;
    let beneficiary = new Beneficiary();
    beneficiary.id = this.beneficiary.id;
    beneficiary.firstName = data.firstName;
    beneficiary.lastName = data.lastName;
    beneficiary.middleName = data.middleName;
    beneficiary.dob = getISoDateString(data.year, data.month, data.day);
    beneficiary.medicaidId = data.medicaidId;
    beneficiary.medicaidEffctDate = !isValueEmpty(beneficiary.medicaidId) ? getISoDateString(data.medicaidYear, data.medicaidMonth, data.medicaidDay) : null;
    beneficiary.medicareId = data.medicareId;
    beneficiary.medicareEffctDate = !isValueEmpty(beneficiary.medicareId) ? getISoDateString(data.medicareYear, data.medicareMonth, data.medicareDay) : null;
    beneficiary.hicn = data.hicn;
    beneficiary.hicnEffctDate = !isValueEmpty(beneficiary.hicn) ? getISoDateString(data.hicnYear, data.hicnMonth, data.hicnDay) : null;
    beneficiary.crispId = data.crispId;
    beneficiary.email = data.email;
    beneficiary.address = data.address;
    beneficiary.primaryPhone = deformatPhone(data.primaryPhone);
    beneficiary.primaryPhoneType = data.primaryPhoneType;
    beneficiary.secondaryPhone = deformatPhone(data.secondaryPhone);
    beneficiary.secondaryPhoneType = data.secondaryPhoneType;
    beneficiary.gender = data.gender;
    beneficiary.hcamId = data.hcamId;
    return beneficiary;
  }

  get firstName() { return this.beneficiaryForm.get('firstName') as AhcFormControl;}
  get lastName() { return this.beneficiaryForm.get('lastName') as AhcFormControl;}
  get month() { return this.beneficiaryForm.get('month');}
  get day() { return this.beneficiaryForm.get('day');}
  get year() { return this.beneficiaryForm.get('year');}
  get gender() { return this.beneficiaryForm.get('gender');}
  get primaryPhone() {return this.beneficiaryForm.get('primaryPhone') as AhcFormControl;}
  get primaryPhoneType() { return this.beneficiaryForm.get('primaryPhoneType') as AhcFormControl;}
  get secondaryPhone() { return this.beneficiaryForm.get('secondaryPhone') as AhcFormControl;}
  get secondaryPhonetype() { return this.beneficiaryForm.get('secondaryPhoneType') as AhcFormControl;}

  get medicaidId() { return this.beneficiaryForm.get('medicaidId');}
  get medicaidDay() {return this.beneficiaryForm.get('medicaidDay');}
  get medicaidMonth() {return this.beneficiaryForm.get('medicaidMonth');}
  get medicaidYear() {return this.beneficiaryForm.get('medicaidYear');}

  get medicareId() { return this.beneficiaryForm.get('medicareId');}
  get medicareDay() {return this.beneficiaryForm.get('medicareDay');}
  get medicareMonth() {return this.beneficiaryForm.get('medicareMonth');}
  get medicareYear() {return this.beneficiaryForm.get('medicareYear');}

  get hicn() { return this.beneficiaryForm.get('hicn');}
  get hicnDay() {return this.beneficiaryForm.get('hicnDay');}
  get hicnMonth() {return this.beneficiaryForm.get('hicnMonth');}
  get hicnYear() {return this.beneficiaryForm.get('hicnYear');}

  get email() { return this.beneficiaryForm.get('email') as AhcFormControl;}
  get address() { return this.beneficiaryForm.get('address');}
  get addrLine1() { return this.beneficiaryForm.get('address').get('addrLine1') as AhcFormControl;}
  get city() { return this.beneficiaryForm.get('address').get('city') as AhcFormControl;}
  get addrState() { return this.beneficiaryForm.get('address').get('state') as AhcFormControl;}
  get zip() { return this.beneficiaryForm.get('address').get('zip') as AhcFormControl;}

  protected isValid() : boolean {
    let valid = true;
    if(this.firstName.invalid){
      valid = false;
    }
    if(this.lastName.invalid){
      valid = false
    }
    if(isEmpty(this.month)){
      this.month.setErrors({"message" : "Month is required"});
      valid = false
    }
    if(isEmpty(this.day)){
      this.day.setErrors({"message" : "Day is required"});
      valid = false
    }
    if(isEmpty(this.year)){
      this.year.setErrors({"message" : "Year is required"});
      valid = false
    }
    if(this.month.invalid){
      valid = false
    }
    if(this.day.invalid){
      valid = false
    }
    if(this.year.invalid){
      valid = false
    }

    if(this.addrLine1.invalid){
      valid = false;
    }
    if(this.city.invalid){
      valid = false;
    }
    if(this.addrState.invalid){
      valid = false;
    }
    if(this.zip.invalid){
      valid = false;
    }

    //check phones:
    let ph = this.primaryPhone.value;
    if(!isValueEmpty(ph)){
      //check if only numbers
      if(!isNumber(ph)){
        this.primaryPhone.setErrors({"invalid" : true});
        this.primaryPhone.invalidMessage = "Phone must have digits only";
        valid = false;
      }
      if(!this.isPhoneValid(ph)){
        this.primaryPhone.setErrors({"invalid" : true});
        this.primaryPhone.invalidMessage = "Phone must be 10 digits long";
        valid = false;
      }
    }

    let ph2 = this.secondaryPhone.value;
    if(!isValueEmpty(ph2)){
      //check if only numbers
      if(!isNumber(ph2)){
        this.secondaryPhone.setErrors({"invalid" : true});
        this.secondaryPhone.invalidMessage = "Phone must have digits only";
        valid = false;
      }
      if(!this.isPhoneValid(ph2)){
        this.secondaryPhone.setErrors({"invalid" : true});
        this.secondaryPhone.invalidMessage = "Phone must be 10 digits long";
        valid = false;
      }
    }

    //phone type must be selected if phone present
    if(!isEmpty(this.primaryPhone)){
      if(isEmpty(this.primaryPhoneType)){
        this.primaryPhoneType.setErrors({'invalid' : true});
        this.primaryPhoneType.invalidMessage = 'Phone type must be selected if phone is specified';
        valid = false
      }
    } else {
      //if phone is empty but phone type is specificied
      if(!isEmpty(this.primaryPhoneType)){
        this.primaryPhoneType.setErrors({'invalid' : true});
        this.primaryPhoneType.invalidMessage = 'Phone must be specified if phone type is selected';
        valid = false;
      }
    }
    //if secondary phone is present then phone type must be present
    if(!isEmpty(this.secondaryPhone)){
      if(isEmpty(this.secondaryPhonetype)){
        this.secondaryPhonetype.setErrors({'invalid' : true});
        valid = false
      }
    } else {
      //if phone is empty but phone type is specified
      if(!isEmpty(this.secondaryPhonetype)){
        this.secondaryPhonetype.setErrors({'invalid' : true});
        this.secondaryPhonetype.invalidMessage = 'Phone must be specified if phone type is selected';
        valid = false;
      }
    }

    //check email valid format
    if(!isEmpty(this.email)){
      if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(this.email.value)){
        this.email.setErrors({"invalid" : true});
        valid = false;
      } else {
        this.email.setErrors(null);
      }
    }
    //perform various medicaid/medicare/hicn related validatinos
    this.resetMedicaid();
    this.resetMedicare();
    this.resetHicn();
    if(isEmpty(this.hicn) && isEmpty(this.medicaidId) && isEmpty(this.medicareId)){
      let errorMessage = "At least one of these must be provided: Health Insurance Claim Number, Medicaid ID, and/or Medicare Beneficiary ID";
      this.medicaidId.setErrors({"message" : errorMessage});
      this.medicareId.setErrors({"message" : errorMessage});
      this.hicn.setErrors({"message" : errorMessage});
      valid = false;
    }
    if(!this.isMedicaidValid()){
      valid = false;
    }
    if(!this.isMedicareValid()){
      valid = false;
    }
    if(!this.isHicnValid()){
      valid = false;
    }
    return valid;
  }

  protected isPhoneValid(phone: string):boolean{
    let tempPhone = deformatPhone(phone);
    if(tempPhone.length < 10 || tempPhone.length > 10) {
      return false;
    }
    return true;
  }

  protected isHicnValid(){
    let valid = true;
    if(!isEmpty(this.hicn)){
      let value = this.hicn.value;
      if(value.length < 7 || value.length > 11){
        this.hicn.setErrors({"message" : "HICN must be 7-11 characters long"});
        valid = false;
      }
      if(!this.alphaNumberic.test(value)){
        this.hicn.setErrors({"message" : "Medicaid Id must be uppercase alphanumeric"});
        valid = false;
      }
      if(isEmpty(this.hicnMonth)){
        this.hicnMonth.setErrors({"message" : "Month is required if ID is provided"});
        valid = false;
      }
      if(isEmpty(this.hicnDay)){
        this.hicnDay.setErrors({"message" : "Day is required if ID is provided"});
        valid = false;
      }
      if(isEmpty(this.hicnYear)){
        this.hicnYear.setErrors({"message" : "Year is required if ID is provided"});
        valid = false;
      }
      if(!this.performValidation("hicnMonth")){
        valid = false;
      }
      if(!this.performValidation("hicnDay")){
        valid = false;
      }
      if(!this.performValidation("hicnYear")){
        valid = false;
      }
    }
    return valid;
  }

  protected isMedicaidValid(){
    let valid = true;
    if(!isEmpty(this.medicaidId)){
      let value = this.medicaidId.value;
      if(value.length > 64){
        this.medicaidId.setErrors({"message" : "Medicaid Id cannot be longer than 64 characters"});
        valid = false;
      }
      if(!this.alphaNumberic.test(value)){
        this.medicaidId.setErrors({"message" : "Medicaid Id must be uppercase alphanumeric"});
        valid = false;
      }
      if(isEmpty(this.medicaidMonth)){
        this.medicaidMonth.setErrors({"message" : "Month is required if ID is provided"});
        valid = false;
      }
      if(isEmpty(this.medicaidDay)){
        this.medicaidDay.setErrors({"message" : "Day is required if ID is provided"});
        valid = false;
      }
      if(isEmpty(this.medicaidYear)){
        this.medicaidYear.setErrors({"message" : "Year is required if ID is provided"});
        valid = false;
      }
      if(!this.performValidation("medicaidMonth")){
        valid = false;
      }
      if(!this.performValidation("medicaidDay")){
        valid = false;
      }
      if(!this.performValidation("medicaidYear")){
        valid = false;
      }
    }
    return valid;
  }

  protected isMedicareValid(){
    let valid = true;
    if(!isEmpty(this.medicareId)){
      let value = this.medicareId.value;
      if(value.length < 7 || value.length > 11){
        this.medicareId.setErrors({"message" : "Medicare Id must be 7-11 characters long"});
        valid = false;
      }
      if(!this.alphaNumberic.test(value)){
        this.medicareId.setErrors({"message" : "Medicare Id must be uppercase alphanumeric"});
        valid = false;
      }
      if(isEmpty(this.medicareMonth)){
        this.medicareMonth.setErrors({"message" : "Month is required if ID is provided"});
        valid = false;
      }
      if(isEmpty(this.medicareDay)){
        this.medicareDay.setErrors({"message" : "Day is required if ID is provided"});
        valid = false;
      }
      if(isEmpty(this.medicareYear)){
        this.medicareYear.setErrors({"message" : "Year is required if ID is provided"});
        valid = false;
      }
      if(!this.performValidation("medicareMonth")){
        valid = false;
      }
      if(!this.performValidation("medicareDay")){
        valid = false;
      }
      if(!this.performValidation("medicareYear")){
        valid = false;
      }
    }
    return valid;
  }

  protected performValidation(name : string){
    let message = '';
    let control = this.required.get(name);
    let min = 1;
    let max = 12;
    if(name.endsWith('Month')){
      message = this.monthMessage;
    } else if (name.endsWith('Day')){
      message = this.dayMessage;
      max = 31;
    } else if (name.endsWith('Year')){
      message = this.yearMessage;
      min = 1900;
      max = this.currentYear;
    }
    let limit = checkLimit(min, max, message);
    let errors = limit(control);
    control.setErrors(errors);
    if(errors){
      return false;
    }
    return true;
  }

  protected resetMedicaid(){
    this.medicaidId.setErrors(null);
    if(isEmpty(this.medicaidId)){
      this.medicaidMonth.setErrors(null);
      this.medicaidMonth.setValue(null);
      this.medicaidDay.setErrors(null);
      this.medicaidDay.setValue(null);
      this.medicaidYear.setErrors(null);
      this.medicaidYear.setValue(null);
    }
  }
  protected resetMedicare(){
    this.medicareId.setErrors(null);
    if(isEmpty(this.medicareId)){
      this.medicareMonth.setErrors(null);
      this.medicareMonth.setValue(null);
      this.medicareDay.setErrors(null);
      this.medicareDay.setValue(null);
      this.medicareYear.setErrors(null);
      this.medicareYear.setValue(null);
    }
  }
  protected resetHicn(){
    this.hicn.setErrors(null);
    if(isEmpty(this.hicn)){
      this.hicnMonth.setErrors(null);
      this.hicnMonth.setValue(null);
      this.hicnDay.setErrors(null);
      this.hicnDay.setValue(null);
      this.hicnYear.setErrors(null);
      this.hicnYear.setValue(null);
    }
  }
  ngOnInit() {
    this.resetRequiredMap();
  }
  ngOnDestroy(){

  }


}

import {Component, OnDestroy, OnInit} from "@angular/core";

@Component({
  selector: 'search-home',
  templateUrl : './search.home.html',

})

export class SearchHomeComponent implements OnInit, OnDestroy {

constructor(){}

  ngOnInit() {

  }

  ngOnDestroy(){

  }

}

import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ScreeningDataService} from "../../helpers/screening.dataservice";
import {Router} from "@angular/router";
import { Beneficiary, BeneficiaryScreening} from "../../model/app.model";
import {
  LOGIN_PATH, SITE_PATH
} from "../../model/app.constants";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {AuthenticationService} from "../login/authentication.service";
import {ScreeningService} from "../../helpers/screening.service";
import {
  isEmpty
} from "../../helpers/common.validator";
import {states} from "../../model/app.states";

import {createBeneficiaryForm, deformatPhone, getISoDateString} from "../../helpers/common.function";
import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";
import {BaseBeneficiary} from "../beneficiary/base.beneficiary";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: [],
  providers: [ScreeningService]
})
export class RegistrationComponent extends BaseBeneficiary implements OnInit, OnDestroy {

  beneficiaryScreening : BeneficiaryScreening;
  //registrationForm : FormGroup;
  states  = states;

  constructor(protected dataService : ScreeningDataService, protected router : Router, protected builder : FormBuilder, protected authService : AuthenticationService,
              protected benDataService : BeneficiaryDataService) {

    super(builder, benDataService);
    this.beneficiaryScreening = new BeneficiaryScreening();
    this.beneficiaryScreening.beneficiary = this.beneficiary;
  }

  createBeneficiaryForm() {
    this.beneficiaryForm = createBeneficiaryForm(this.beneficiary, this.builder);
  }

  public onEnterKey(event){
    if(event.keyCode === 13){
      this.onSubmitForm();
    }

  }

  public onSubmitForm() {
    this.reset();
    this.submitted = true;
    if (this.isValid()) {
      let beneficiary = this.createBeneficiary();
      this.benDataService.setBeneficiary(beneficiary);
      this.postSaveAction();
    }
  }

  protected postSaveAction(){
    this.router.navigate([SITE_PATH]).then();
  }

  protected createRegistrationForm(beneficiary : Beneficiary) : FormGroup {
    return createBeneficiaryForm(beneficiary, this.builder);
  }

  public onMedicDateKey(event){
    let name = event.target.id;
    if (!isEmpty(this.medicaidId)) {
      this.performValidation(name);
    }
    if(!isEmpty(this.medicareId)){
      this.performValidation(name);
    }
    if(!isEmpty(this.hicn)){
      this.performValidation(name);
    }
  }



  public onMedicaidKey(event){
    this.resetMedicaid();
  }

  public onMedicareKey(event){
    this.resetMedicare();
  }

  public onHicnKey(event){
    this.resetHicn();
  }


  public onKey(event){
    let name = event.target.id;
    let control : FormControl = this.required.get(name) as FormControl;
    if(control){
      if(!control.invalid){
        control.setErrors(null);
      }
    }
    //if hicn, medicaid or medicare then reset others too
    if(name === 'hicn' || name === 'medicaidId' || name === 'medicareId'){
      let hicn = this.required.get('hicn');
      let medicaid = this.required.get('medicaidId');
      let medicare = this.required.get('medicareId');
      hicn.setErrors(null);
      medicaid.setErrors(null);
      medicare.setErrors(null);
    }
  }

  public back(){
    this.router.navigate([this.dataService.getBackRoute()]).then();
  }
  ngOnInit() {
    super.ngOnInit();
    window.scroll(0,0);
    this.submitted = false;
    if(!this.authService.isLoggedIn()){
      this.router.navigate([LOGIN_PATH]).then();
    }

  }
  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ScreeningDataService} from "../../helpers/screening.dataservice";
import {BeneficiaryScreening, BinaryIndicator, HrsnType, RiskIndicator} from "../../model/app.model";
import {Subscription} from "rxjs/internal/Subscription";
import {clone, getIcon, padId, toAddressString} from "../../helpers/common.function";
import {BENEFICIARY_PATH, LOGIN_PATH, NeedTaxonomyMap} from "../../model/app.constants";
import {Router} from "@angular/router";
import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";
import {NeedResourceService} from "../../helpers/need.resource.service";
import {UrlService} from "../../system/url.service";


@Component({
  selector: '',
  templateUrl: './result.component.html',
  styleUrls: [],
  providers : [NeedResourceService]
})
export class ResultComponent implements OnInit, OnDestroy {
  subscription : Subscription;
  beneficiaryScreening : BeneficiaryScreening;
  needToResourceMap : any[] = [];
  socialNeeds : string[] = [];
  constructor(private dataService : ScreeningDataService, private router : Router,
              private beneDataService : BeneficiaryDataService, private needResourceService : NeedResourceService, private urlService : UrlService) {

    /*
      Resource directory backend cannot passback record with corresponding taxonomy code. It takes the array of codes and
      then build array of records by putting those codes in a heirarchy and then return the records. In this process, the
      taxonomy code-to-record correspondence is lost. For this reason we have to call RD backend multiple times individually
      for each Social Need. It's not an ideal solution but only available to us given the limitations.
     */
    let temp = this.dataService.getBeneficiaryScreening();
    if(temp){
      this.beneficiaryScreening = temp;
      //fill needs that this screening has
      this.populateNeeds();
      this.fetchResources();
    }else {
      this.router.navigate([LOGIN_PATH]).then();
    }

  }
  public hasSocialNeed(label : string) : boolean {
    let need : boolean = false;
    if(this.beneficiaryScreening.screening != undefined){
      let sections = this.beneficiaryScreening.screening.sections;
      for(let i = 0; i < sections.length; i++){
        if(sections[i].label === label && (sections[i].need && sections[i].need.toString() == "Y")){
          need = true;
          break;
        }
      }
    }
    return need;
  }

  getLabel(hrsnType : HrsnType){
    return HrsnType[hrsnType];
  }

  get communityDwelling () : boolean {
    if(this.beneficiaryScreening){
      let riskIndicator = this.beneficiaryScreening.riskIndicator;
      if(riskIndicator && riskIndicator.communityDwelling.toString() === "Y"){
        return true;
      }
    }
    return false;

  }
  get highRisk () : boolean {
    if (this.beneficiaryScreening.highRisk.toString() === "Y") {
      return true;
    }
    return false;
  }

  public getIcon(label) : string {
    return getIcon(label);
  }

  private fetchResources(){
    let me = this;
    let address = toAddressString(this.beneficiaryScreening.beneficiary.address);
    for(let i = 0; i < this.socialNeeds.length; i++){
      let need = this.socialNeeds[i];
      let taxonomy = NeedTaxonomyMap[need] as string[];
      let resourceSubscription = me.needResourceService.findResourceByNeed(address, taxonomy).subscribe(data => {
        let results = data as any[];
        if(results){
          //only display two resources for each need
          let length = (results.length > 2 ? 2 : results.length);
          //needs should have their own array data structure where they can be displayed together by looping off from
          let resourceArray : any[] = [];
          for(let i = 0; i < length; i++){
            let resource = results[i];
            resource['taxonomy'] = NeedTaxonomyMap[need];
            resource['hrsnType'] = need;
            resourceArray.push(resource);
          }
          if(resourceArray.length > 0){
            me.needToResourceMap.push(resourceArray);
          }
        }
      }, error1 => {}, ()=>{
        resourceSubscription.unsubscribe();
      });
    }
  }

  public gotoResourceDirectory(){
    window.open(this.urlService.resourceDirUIUrl, '_blank');
  }

  private populateNeeds(){
    let keys = Object.keys(HrsnType);
    keys.forEach((key => {
      let value = HrsnType[key];
      if(this.hasSocialNeed(value)){
        this.socialNeeds.push(key);
      }
    }));
  }
  protected gotoBeneficiaryPage(){
    let clonedBeneficiary = clone(this.beneficiaryScreening.beneficiary);
    this.beneDataService.setBeneficiary(clonedBeneficiary);
    this.router.navigate([BENEFICIARY_PATH]).then();
  }

  ngOnInit() {


  }
  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
}

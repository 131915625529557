import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {DialogSystemManager} from "../../system/dialog.system.manager";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: "./confirm.dialog.html"
})

export class ConfirmDialog {
  message : string = null;
  constructor(public dialogRef : MatDialogRef<ConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data : any, private dialogManager : DialogSystemManager){
    this.dialogManager.register('ConfirmDialog', this.dialogRef);
    this.message = data.message;
  }

}

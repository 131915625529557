//manager service for initializing/cleaning up

import {Injectable} from "@angular/core";

@Injectable()
export class SystemManager {
  private services : Array<OnReset>;
  constructor(){
    this.services = new Array<OnReset>();
  }

  public addService(newService : OnReset){
    let exists = false;
    for(let i = 0; i < this.services.length; i++){
      if(this.services[i].getName() === newService.getName()){
        exists = true;
      }
    }
    if(!exists){
      this.services.push(newService);
    }
  }

  public reset () {
    for(let i = 0; i < this.services.length; i++){
      this.services[i].OnReset();
    }
  }
}

import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {throwError} from "rxjs/internal/observable/throwError";
import {AUTH_HEADER, COOKIE_HEADER, TOKEN} from "../../model/app.constants";
import {AppErrorHandler} from "../../helpers/app.errorhandler";
import {Router} from "@angular/router";
import {EncryptionService} from "../../helpers/encryption.service";
import {UrlService} from "../../system/url.service";
import {SystemManager} from "../../system/system.manager";

@Injectable()
export class AuthenticationService {
  loggedIn : boolean = false;

  constructor(private systemService: UrlService, private http: HttpClient, protected router : Router, private errorHandler : AppErrorHandler, private encryption : EncryptionService,
              private systemManager : SystemManager){

  }

  public login(username: string, password: string){
    this.logout();
    let user = {"username" : username, "password" : password };
    let url = this.systemService.loginUrl;
    return this.http.post<any>(url, user,  {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'}).append('Access-Control-Expose-Headers', AUTH_HEADER)
        .append('Access-Control-Expose-Headers', COOKIE_HEADER),
      observe: 'response' }).pipe(map((res:any) => {
      if (res) {
        if(res.status != 200 ){
          this.loggedIn = false;
          throwError(res.message);
        } else {
          var hdrToken = res.headers.get(AUTH_HEADER);
          this.encryption.setKey(res.headers.get(COOKIE_HEADER));
          if(hdrToken){
            var encrypted = this.encryption.encrypt(JSON.stringify({ username, token:  hdrToken}));
            localStorage.setItem(TOKEN, encrypted);
            this.loggedIn = true;
            return res;
          } else {
            this.loggedIn = false;
            throwError('Error receiving authorization');
          }

        }

      }
    }), catchError(this.errorHandler.handleError));

  }

  isLoggedIn(){
    if(localStorage.getItem(TOKEN)){
      return true;
    }
    return false;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(TOKEN);
    this.systemManager.reset();
    return !this.isLoggedIn();
  }


}

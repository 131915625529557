import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ScreeningQuestion, Section} from '../../../../model/app.model';

export const NONE = 'None of the above';

@Component({
  selector: 'screening-question',
  templateUrl : './question.component.html'
})

export class QuestionComponent {

  @Input() parent : Section;
  @Input() question : ScreeningQuestion;
  @Output() questionSelected : EventEmitter<ScreeningQuestion> = new EventEmitter<ScreeningQuestion>();

  public onResponded(event) : void {
    this.question.answered = true;
    this.parent.answered = true;
    for(let i = 0; i < this.question.responses.length; i++){
      let temp = this.question.responses[i];
      if(event.id === temp.id){
        temp.selected = event.selected;
      } else {
        if(!this.question.multipleChoice){
          temp.selected = false;
        }
        //if it was notes field then wipe off notes
        if(temp.hasNotes){
          temp.notes = undefined;
        }
      }
      //handle multiple choice scenario
      if(this.question.multipleChoice){
        //unselect others if none of above.
        if(event.response === NONE){
          if(temp.id != event.id){
            temp.selected = false;
          }
        }
        //if not "none of the above" then unselect any "none of the above"
        else {
          if(temp.response === NONE){
            temp.selected = false;
          }
        }
      }

    }

  }

}

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UrlService} from "../system/url.service";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class NeedResourceService {
  constructor(private http: HttpClient, private urlService : UrlService){}
  public findResourceByNeed(address : string, taxonomy : string[]){
    let url = encodeURI(this.urlService.resourceDirSearchUrl + '?location=' + address + '&' + 'taxonomy_ids=' + taxonomy);
    return this.http.get(url).pipe(map((response:any)=>{
      return response;
    }), catchError(err => {throw  err}));
  }
}

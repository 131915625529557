import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {CaseEventService} from "./event.service";
import {FormBuilder, FormControl, Validators, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {CaseEvent, DurationCode, EncounterType, ProviderType, ServiceType} from "../../../model/app.model";
import {Subscription} from "rxjs";
import {isValueEmpty} from "../../../helpers/common.validator";
import {DatePipe} from "@angular/common";
import {LOGIN_PATH} from "../../../model/app.constants";
import {getLocalDateInISOFormat} from "../../../helpers/common.function";
import {DialogSystemManager} from "../../../system/dialog.system.manager";

@Component({
  selector : 'app-add-event',
  templateUrl : './add.event.html',
  providers : [CaseEventService, DatePipe]

})

export class AddEventComponent implements OnInit, OnDestroy {
  caseEventForm : FormGroup;
  caseEvent : CaseEvent = new CaseEvent();
  subscription : Subscription;
  serviceTypes;
  durationCodes;
  encounterTypes;
  providerTypes;
  submitted : boolean = false;
  error : string;
  hasError:boolean = false;
  caseId : string;
  name : string;
  constructor(private caseEventService : CaseEventService, protected formBuilder: FormBuilder,
              public dialogRef : MatDialogRef<AddEventComponent>, @Inject(MAT_DIALOG_DATA) public data : any, private dialogManager : DialogSystemManager){

    this.dialogManager.register('AddEventComponent', this.dialogRef);
    this.caseId = data['caseId'];
    this.name = data['name'];
    this.serviceTypes = Object.keys(ServiceType).sort();
    this.durationCodes = Object.keys(DurationCode).sort();
    this.encounterTypes = Object.keys(EncounterType).sort();
    this.providerTypes = Object.keys(ProviderType).sort();

    let eventDate = getLocalDateInISOFormat();
    this.caseEventForm = this.formBuilder.group({serviceType : new FormControl(this.caseEvent.serviceType, [Validators.required]),
      encounterType : new FormControl(this.caseEvent.encounterType, [Validators.required]),
      durationCode : new FormControl(this.caseEvent.durationCode, [Validators.required]), providerType : new FormControl(this.caseEvent.providerType, [Validators.required]),
      comments: new FormControl(this.caseEvent.comments), eventDateTime : new FormControl(eventDate, [Validators.required])});
  }

  protected getServiceTypeLabel(serviceType : ServiceType){
    return ServiceType[serviceType];
  }

  protected getDurationCodesLabel(durationCode : DurationCode){
    return DurationCode[durationCode];
  }

  protected getEncounterTypeLabel(encounterType : EncounterType){
    return EncounterType[encounterType];
  }

  protected getProviderTypeLabel(providerType : ProviderType){
    return ProviderType[providerType];
  }

  private isValid() : boolean {

    let valid = true;
    if(isValueEmpty(this.providerType.value)){
      this.providerType.setErrors({"message" : "Provider type is required"});
      valid = false;
    }
    if(isValueEmpty(this.serviceType.value)){
      this.serviceType.setErrors({"message" : "Service type is required"});
      valid = false;
    }
    if(isValueEmpty(this.encounterType.value)){
      this.encounterType.setErrors({"message" : "Encounter type is required"});
      valid = false;
    }
    if(isValueEmpty(this.durationCode.value)){
      this.durationCode.setErrors({"message" : "Duration code is required"});
      valid = false;
    }
    return valid;
  }

  private createCaseEvent() : CaseEvent {
    let caseEvent = new CaseEvent();
    let formData = this.caseEventForm.value;
    caseEvent.caseId = this.caseId;
    caseEvent.serviceType = formData.serviceType;
    caseEvent.encounterType = formData.encounterType;
    caseEvent.durationCode = formData.durationCode;
    caseEvent.providerType = formData.providerType;
    caseEvent.dateCreated = Date.parse(formData.eventDateTime).toString();
    caseEvent.comments = formData.comments;
    return caseEvent;
  }

  private reset(){
    this.error = null;
    this.hasError = false;
    this.submitted = false;
  }

  protected saveCaseEvent(){
    const me = this;
    me.reset();
    me.submitted = true;
    if(me.isValid()){
      me.caseEvent = me.createCaseEvent();
      let savedData = {isSaved : false};
      this.subscription = this.caseEventService.addCaseEvent(this.caseEvent).subscribe(data=>{
        let savedCaseEvent : CaseEvent = data['body'];
        savedData['caseEvent'] = savedCaseEvent;
        savedData.isSaved = true;
      }, error1 => {
        savedData.isSaved = false;
        me.error = error1.error;
        me.hasError = true;
      }, ()=>{
        if(!me.hasError){
          me.dialogRef.close(savedData);
        }
      });
    }
  }

  get serviceType(){
    return this.caseEventForm.get('serviceType');
  }
  get encounterType(){
    return this.caseEventForm.get('encounterType');
  }
  get durationCode(){
    return this.caseEventForm.get('durationCode');
  }
  get providerType(){
    return this.caseEventForm.get('providerType');
  }
  get comments(){
    return this.caseEventForm.get('comments');
  }
  get eventDateTime(){
    return this.caseEventForm.get('eventDateTime');
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
}

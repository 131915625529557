import {OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AuthenticationService} from "../login/authentication.service";
import {LOGIN_PATH} from "../../model/app.constants";
import {Subscription} from "rxjs";

export class BaseComponent implements OnInit, OnDestroy{

  protected router : Router;
  protected authService : AuthenticationService;
  subscription : Subscription;
  constructor(private _router : Router, private _authService : AuthenticationService){
    this.router = _router;
    this.authService = _authService;
  }

  protected init(){

  }
  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.router.navigate([LOGIN_PATH]).then();
    } else {
      this.init();
    }
  }

  ngOnDestroy(){

  }
}

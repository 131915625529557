import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LOGIN_PATH} from "../../../model/app.constants";
import {AuthenticationService} from "../../login/authentication.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {CaseEventService} from "./event.service";
import {Beneficiary, CaseEvent, DurationCode, EncounterType, ProviderType, ServiceType} from "../../../model/app.model";
import {MatDialog, MatDialogRef} from "@angular/material";
import {AddEventComponent} from "./addevent.component";

@Component({
  selector: 'app-case-events',
  templateUrl: './event.component.html',
  providers : [CaseEventService]
})
export class EventComponent implements OnInit, OnDestroy {

  @Input() caseId : string;
  @Input() beneficiary : Beneficiary;
  subscription : Subscription;
  caseEvents : Array<CaseEvent> = new Array<CaseEvent>();
  protected dialogRef : MatDialogRef<any, any> = null;
  constructor(private authService : AuthenticationService, private router : Router, private eventService : CaseEventService, private dialog: MatDialog) { }

  private fetchCaseEvents(){
    const me = this;
    this.subscription = this.eventService.fetchCaseEvents(this.caseId).subscribe(data => {
      me.caseEvents = data['body'];
    }, error1 => {

    });
  }

  protected getServiceType(serviceType : ServiceType) : string {
    return ServiceType[serviceType];
  }
  protected getEncounterType(encounterType : EncounterType) : string {
    return EncounterType[encounterType];
  }
  protected getDurationCode(durationCode : DurationCode) : string {
    return DurationCode[durationCode];
  }
  protected getProviderType(value : ProviderType) : string {
    return ProviderType[value];
  }

  protected addEvent(){
    const me = this;
    let data = {'caseId' : this.caseId, 'name' : me.beneficiary.firstName};
    this.dialogRef = this.dialog.open(AddEventComponent, { height: 'auto', width: '75%', data: data});
    this.dialogRef.afterClosed().subscribe(result => {
      if(result && result.isSaved){
        me.caseEvents.push(result.caseEvent);
      }
    });
  }


  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.router.navigate([LOGIN_PATH]).then();
    } else {
      this.fetchCaseEvents();
    }
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}

import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {Role, User} from "../../model/app.model";
import {UrlService} from "../../system/url.service";
import {headers} from "../../model/app.constants";
import {catchError, map} from "rxjs/operators";
import {AppErrorHandler} from "../../helpers/app.errorhandler";


@Injectable()
export class UserService{
  constructor(private http : HttpClient, private systemService : UrlService, private handler : AppErrorHandler){}

  public addUser(user : User){
    let url = this.systemService.signupUrl;
    return this.http.put(url, user, {headers: headers, observe:"response"}).pipe(map((response : any)=>{
      return response;
    }), catchError( error => {
      throw error;
    }));

  }
  public retrieveUserById(id : string){
    let url = this.systemService.userUrl + '/' + id;
    return this.http.get(url, {headers : headers, observe: 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error=>{ throw error;}));

  }
  public retrieveUserByUsername(username : string){
    let url = this.systemService.userUrl + '?username=' + username;
    return this.http.get(url, {headers : headers, observe: 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error=>{ throw error;}));
  }
  public retrieveAllUsers(page : number, size: number){
    let url = this.systemService.userUrl + '/all' + '?page=' + page + '&size=' + size;
    return this.http.get(url, {headers : headers, observe: 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error=>{ throw error;}));
  }

  public retrieveNavigators(){
    let url = this.systemService.userUrl + '/navigators';
    return this.http.get(url, {headers : headers, observe: 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error=>{ throw error;}));
  }

  public addUserAuthority(id : String, role : Role){
    let url = this.systemService.userUrl + '/' + id + '/authorities/' + role.toString() + '?action=add';
    return this.http.post(url, null,{headers : headers, observe: 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error=>{ throw error;}));
  }
  public removeUserAuthority(id : string, role : Role){
    let url = this.systemService.userUrl + '/' + id + '/authorities/' + role.toString() + '?action=remove';
    return this.http.post(url, null,{headers : headers, observe: 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error=>{ throw error;}));
  }
  public updateUser(user : User){
    let url = this.systemService.userUrl + '/' + user.id;
    return this.http.post(url, user, {headers : headers, observe: 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error=>{ throw error;}));
  }
  public enableUser(id : string) {
    let url = this.systemService.userUrl + '/' + id + '/enable/true';
    return this.http.post(url, {headers: headers, observe: 'response'}).pipe(map((response: any) => {
      return response;
    }), catchError(error => {
      throw error;
    }));
  }
  public disableUser(id : string){
      let url = this.systemService.userUrl + '/' + id + '/enable/false';
      return this.http.post(url, {headers: headers, observe : 'response'}).pipe(map((response : any)=>{
        return response;
      }), catchError(error=>{
        throw error;
      }));
  }
  public managePassword(tokenId: string, token: string, password: string){
    let url = this.systemService.userUrl + '/password/manage' + '?tokenId=' + tokenId + '&token=' + token + '&password=' + password;
    return this.http.post(url, {headers: headers, observe : 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error=>{
      throw error;
    }));
  }
  public resetPassword(email : string){
    let url = this.systemService.userUrl + '/password/reset' + '?email=' + email;
    return this.http.post(url, {headers: headers, observe : 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error=>{
      throw error;
    }));
  }
  public uninviteUser(userId : string){
    let url = this.systemService.userUrl + '/' + userId + '/invitations';
    return this.http.delete(url, {headers : headers, observe : 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error =>{
      throw error;
    }))
  }
  public deleteUser(username : string, reason: string){
    let url = this.systemService.userUrl + '/' + username + '?reason=' + reason;
    return this.http.delete(url, {headers : headers, observe : 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error =>{
      throw error;
    }))
  }

  public reinviteUser(userId : string){
    let url = this.systemService.userUrl + '/' + userId + '/invitations/reinvite';
    return this.http.post(url, {headers : headers, observe : 'response'}).pipe(map((response : any)=>{
      return response;
    }), catchError(error =>{
      throw error;
    }))
  }

}

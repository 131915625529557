import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {NavigatorService} from "./navigator.service";
import {Router} from "@angular/router";
import {LOGIN_PATH} from "../../model/app.constants";
import {Beneficiary, Navigator, User} from "../../model/app.model";
import {AuthenticationService} from "../login/authentication.service";
import {MatDialog, MatDialogRef, MatTable, MatTableDataSource} from "@angular/material";
import {AddupdateNavigatorComponent} from "./addupdate.navigator.component";
import {BaseComponent} from "../common/base.component";

@Component({
  selector : 'app-navigator',
  templateUrl : './navigator.component.html',
  providers : [NavigatorService]
})

export class NavigatorComponent extends BaseComponent{
  displayColumns : string [] = ['name', 'site', 'status', 'dateCreated', 'action'];
  @Input() beneficiary : Beneficiary;
  navigators : Navigator[] = [];
  hasError : boolean = false;
  errorMessage : string;
  dialogRef : MatDialogRef<any, any>;
  navigatorDataSource : MatTableDataSource<Navigator>;
  @ViewChild(MatTable, {static : false}) navigatorsTable : MatTable<any>;
  constructor(private navigatorService : NavigatorService, protected router : Router, protected authService : AuthenticationService, private dialog : MatDialog){
    super(router, authService);
  }

  public attachNavigator(){
    let me = this;
    let data = {'beneficiary' : this.beneficiary, 'isUpdate' : false};
    this.dialogRef = me.dialog.open(AddupdateNavigatorComponent, { height: 'auto', width: '40%', data: data});
    let sub = me.dialogRef.afterClosed().subscribe(result=>{
      if(result['saved']){
        me.navigators.unshift(result['navigator']);
        me.navigatorsTable.renderRows();
      }
    }, error1 => {}, ()=>{
      sub.unsubscribe();
    });
  }

  public editNavigator(navigator : Navigator) {
    let me = this;
    let data = {'navigator': navigator, 'isUpdate': true};
    this.dialogRef = this.dialog.open(AddupdateNavigatorComponent, {height: 'auto', width: '40%', data: data});
    this.dialogRef.afterClosed().subscribe(result => {
      let updatedNavigator = result['navigator'];
      if(updatedNavigator){
        this.navigators.forEach((nav, idx, arr) => {
          if(nav.id === updatedNavigator.id){
            arr[idx] = updatedNavigator;
          }
        });
      }
    }, error1 => {}, ()=>{
      me.navigatorsTable.renderRows();
    });
  }

  protected init(){
    let me = this;
    if(!this.authService.isLoggedIn()){
      this.router.navigate([LOGIN_PATH]).then();
    } else {
      let subscription = this.navigatorService.fetchNavigatorsByBeneId(this.beneficiary.id).subscribe(data=>{
        me.navigators = data['body'] as Navigator[];
        me.navigatorDataSource = new MatTableDataSource<Navigator>(me.navigators);
      }, error1 => {
        this.hasError = true;
        this.errorMessage = error1['error'];
      },()=>{
        subscription.unsubscribe();
      });
    }
  }
}

import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {switchMap} from "rxjs/operators";

@Injectable()
export class IdleLogoutService{
  idleTime : number = 900000; //15 minutes
  subscription;
  public logoutTimer : Observable<any>;
  subject : BehaviorSubject<any>;
  constructor(){
    let me = this;
    this.subject = new BehaviorSubject(['timeout']);
    this.logoutTimer = this.subject.asObservable().pipe(
      switchMap(() => timer(me.idleTime, me.idleTime)),
      switchMap(() => {
        return this.subject.getValue();
      }));
  }

  public reset(){
    this.subject.next(['reset']);
  }

}

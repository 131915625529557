import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import { Beneficiary} from "../../model/app.model";
import {DatePipe} from "@angular/common";
import {isValueEmpty} from "../../helpers/common.validator";
import {BeneficiaryService} from "./beneficiary.service";
import {Subscription} from "rxjs";
import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";
import {BENEFICIARY_PATH, SEARCH_BENEFICIARY_HOME} from "../../model/app.constants";
import {Router} from "@angular/router";
import {ScreeningDataService} from "../../helpers/screening.dataservice";
import {DialogSystemManager} from "../../system/dialog.system.manager";


const NOT_ENTERED : string = '(Not entered)';
@Component({
  selector: 'app-merge-beneficiaries',
  templateUrl : './merge.beneficiary.html',
  providers : [DatePipe]

})

export class MergeBeneficiary {
  beneficiaries : Beneficiary[];
  left : Beneficiary;
  right : Beneficiary;
  map : any;
  excluded : string[];
  updatedBeneficiary : Beneficiary;
  updateSubscription : Subscription;
  hasError : boolean = false;
  errorMessage : string = '';
  constructor( public dialogRef : MatDialogRef<MergeBeneficiary>, @Inject(MAT_DIALOG_DATA) public data : any, private router : Router, private dataService : ScreeningDataService,
               private datePipe : DatePipe, private beneService : BeneficiaryService, private beneDataService : BeneficiaryDataService, private dialogManager : DialogSystemManager){

    this.dialogManager.register('MergeBeneficiary', this.dialogRef);
    this.beneficiaries = data.beneficiaries;
    if(data && data.beneficiaries && data.beneficiaries.length === 2){
      let temp1 = this.beneficiaries[0];
      let temp2 = this.beneficiaries[1];
      if(temp1.dateCreated < temp2.dateCreated){
        this.left = temp1;
        this.right = temp2;
        this.updatedBeneficiary = temp1;
      } else {
        this.left = temp2;
        this.right = temp1;
        this.updatedBeneficiary = temp2;
      }
    }
    this.constructBeneficiaryMap();
  }

  private formatBeneficiary(){
    this.updatedBeneficiary.medicaidEffctDate = this.updatedBeneficiary.medicaidEffctDate?new Date(this.updatedBeneficiary.medicaidEffctDate).toISOString():null;
    this.updatedBeneficiary.medicareEffctDate = this.updatedBeneficiary.medicareEffctDate?new Date(this.updatedBeneficiary.medicareEffctDate).toISOString():null;
    this.updatedBeneficiary.hicnEffctDate = this.updatedBeneficiary.hicnEffctDate?new Date(this.updatedBeneficiary.hicnEffctDate).toISOString():null;
  }

  public merge(){
    const me = this;
    this.formatBeneficiary();
    me.updateSubscription = this.beneService.mergeBeneficiary(this.updatedBeneficiary, this.right.id).subscribe(response=>{
      me.updatedBeneficiary = response['body'];
    }, error1 => {
      me.hasError = true;
      me.errorMessage = error1.error;
    }, () => {
      if(me.updateSubscription){
        me.updateSubscription.unsubscribe();
      }
      me.beneDataService.setBeneficiary(me.updatedBeneficiary);
      let tempBeneficiaries : Beneficiary[] = new Array<Beneficiary>();
      let searchBeneficiaries = me.beneDataService.getSearchResultBeneficiaries();
      for(let i = 0; i < searchBeneficiaries.length; i++){
        let arrBeneficiary = searchBeneficiaries[i];
        if(arrBeneficiary.id != me.right.id && arrBeneficiary.id != me.left.id){
          tempBeneficiaries.push(arrBeneficiary);
        }
      }
      tempBeneficiaries.push(me.updatedBeneficiary);
      me.beneDataService.setSearchResultBeneficiaries(tempBeneficiaries);
      this.dataService.setBackRoute(SEARCH_BENEFICIARY_HOME);
      this.router.navigate([BENEFICIARY_PATH]).then();
      me.dialogRef.close();
    });
  }

  public selected(event){
    if(event.value === NOT_ENTERED){
      this.updatedBeneficiary[event.source.name] = null;
    } else {
      if(
        (event.source.name.startsWith('addrLine1')) ||
        (event.source.name.startsWith('addrLine2')) ||
        (event.source.name.startsWith('city')) ||
        (event.source.name.startsWith('state')) ||
        (event.source.name.startsWith('zip')) ||
        (event.source.name.startsWith('zipExtension'))){
        this.updatedBeneficiary['address'][event.source.name] = event.value;
      }else{
        this.updatedBeneficiary[event.source.name] = event.value;
      }
    }
  }

  private constructBeneficiaryMap() {
    this.map = {"elements" : []};

    this.excluded = new Array<string>();
    this.excluded.push('name');
    this.excluded.push('id');
    this.excluded.push('dateCreated');
    this.excluded.push('dateUpdated');
    this.excluded.push('createdBy');
    this.excluded.push('updatedBy');
    this.excluded.push('deleted');
    this.excluded.push('crispId');
    this.excluded.push('regOrgId');
    this.excluded.push('snsHomeId');
    this.excluded.push('dobTime');
    this.excluded.push('screeningIds');

    for(let field in this.left){
      if(field === 'address'){
        let leftAddress = this.left[field];
        let rightAddress = this.right[field];
        for(let addrField in leftAddress){
          this.createJson(addrField, leftAddress[addrField], rightAddress[addrField]);
        }
      } else {
        this.createJson(field, this.left[field], this.right[field]);
      }
    }
  }

  private createJson(field : string, leftValue : string, rightValue : string){
    if(leftValue != rightValue){
      let json = {};
      if(!this.excluded.includes(field)){
        json['field'] = field;
        let temp = this.isDate(field);
        if(temp){
          json['leftValue'] = this.formatDate(leftValue);
          json['rightValue'] = this.formatDate(rightValue);
        }else {
          json['leftValue'] = leftValue?leftValue:NOT_ENTERED;
          json['rightValue'] = rightValue?rightValue:NOT_ENTERED;
        }
        json['label'] = this.getFieldName(field);
        json['selectedValue'] = '';
        this.map.elements.push(json);
      }
    }
  }

  private isDate(field) : boolean {
    return (field.indexOf('Date') > -1);
  }

  private formatDate(value : string) : string {
    if(isValueEmpty(value)){
      return '(Not entered)';
    }
    return this.datePipe.transform(value, 'dd-MMM-yyyy');
  }

  private getFieldName(field : string): string {
    if(field.startsWith('firstName')){
      return 'First Name';
    }
    if(field.startsWith('lastName')){
      return 'Last Name';
    }
    if(field.startsWith('middleName')){
      return 'Middle Name';
    }
    if(field.startsWith('medicaidId')){
      return 'Medicaid ID';
    }
    if(field.startsWith('medicaidEffctDate')){
      return 'Medicaid Effective Date';
    }
    if(field.startsWith('medicareId')){
      return 'Medicare ID';
    }
    if(field.startsWith('medicareEffctDate')){
      return 'Medicare Effective Date';
    }
    if(field.startsWith('hicn')){
      return 'Health Insurance Claim Number (HICN)';
    }
    if(field.startsWith('hicnEffctDate')){
      return 'HICN Effective Date';
    }
    if(field.startsWith('gender')){
      return 'Gender';
    }
    if(field ==='primaryPhone'){
      return 'Primary Phone';
    }
    if(field === 'primaryPhoneType'){
      return 'Primary Phone Type';
    }
    if(field === 'secondaryPhone'){
      return 'Secondary Phone';
    }
    if(field === 'secondaryPhoneType'){
      return 'Secondary Phone Type';
    }
    if(field.startsWith('email')){
      return 'Email';
    }
    if(field.startsWith('hcamId')){
      return 'HCAM ID';
    }
    if(field.startsWith('addrLine1')){
      return 'Address Line 1';
    }
    if(field.startsWith('addrLine2')){
      return 'Address Line 2';
    }
    if(field.startsWith('city')){
      return 'City';
    }
    if(field.startsWith('state')){
      return 'State';
    }
    if(field.startsWith('zip')){
      return 'Zip';
    }
    if(field.startsWith('zipExtension')){
      return 'Zip 4-digit Extension';
    }
    return 'Not entered';
  }
}

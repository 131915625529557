import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatExpansionModule, MatGridListModule, MatInputModule, MatSelectModule} from "@angular/material";
import { AppComponent } from './app.component';
import { LoginComponent } from "./components/login/login.component";
import { AuthenticationService } from "./components/login/authentication.service";
import { AppErrorHandler } from "./helpers/app.errorhandler";
import { JwtInterceptor } from "./helpers/jwt.interceptor";
import { HomeComponent } from './components/home/home.component';
import { ScreeningComponent } from './components/screening/screening.component';
import { NavComponent } from './components/navbar/nav.component';
import { ConsentComponent } from './components/consent/consent.component';
import { EncryptionService } from "./helpers/encryption.service";
import { ScreeningDataService } from "./helpers/screening.dataservice";
import { RegistrationComponent } from './components/registration/registration.component';
import { Authguard } from "./components/login/authguard";
import { SectionComponent } from "./components/screening/section/section.component";
import { QuestionComponent } from "./components/screening/section/question/question.component";
import { ResponseComponent } from "./components/screening/section/question/response/response.component";
import { ResultComponent } from './components/result/result.component';

import {
  SITE_PATH,
  BENEFICIARY_PATH,
  LOGIN_PATH,
  SCREENING_CONSENT_PATH,
  SCREENING_PATH,
  SCREENING_REGISTRATION_PATH,
  SCREENING_RESULT_PATH,
  SEARCH_BENEFICIARY_LIST,
  SEARCH_BENEFICIARY_PATH,
  SEARCH_BENEFICIARY_HOME,
  USER_MANAGEMENT,
  NEW_USER_PWD, RESET_USER_PWD, RESET_PASSWORD, NAVIGATION_LIST
} from "./model/app.constants";
import { SiteService } from "./helpers/site.service";
import { SearchComponent } from "./components/search/search.component";
import { SearchService } from "./components/search/search.service";
import { SearchListComponent } from "./components/search/search.list.component";
import { UrlService } from "./system/url.service";
import { BeneficiaryComponent } from './components/beneficiary/beneficiary.component';
import {BeneficiaryDataService} from "./helpers/beneficiary.dataservice";
import {SystemManager} from "./system/system.manager";
import {UpdateBeneficiaryComponent} from "./components/beneficiary/update.beneficiary";
import {BeneficiaryService} from "./components/beneficiary/beneficiary.service";
import { EventComponent } from './components/navcase/event/event.component';
import { NeedComponent } from './components/navcase/need/need.component';
import {NeedAddupdateComponent} from "./components/navcase/need/need.addupdate.component";
import {AddEventComponent} from "./components/navcase/event/addevent.component";
import {MatTableModule, MatCheckboxModule, MatRadioModule, MatAutocompleteModule} from "@angular/material";
import {MergeBeneficiary} from "./components/beneficiary/merge.beneficiary";
import { UserComponent } from './components/user/user.main';
import {ManageUserComponent} from "./components/user/user.manage";
import {PasswordComponent} from "./components/user/password.manage";
import {PasswordResetComponent} from "./components/user/password.reset";
import {ConfirmDialog} from "./components/common/confirm.dialog";
import {IdleLogoutService} from "./system/idle.logout.service";
import {DialogSystemManager} from "./system/dialog.system.manager";
import {SearchHomeComponent} from "./components/search/search.home";
import { ReferralComponent } from './components/navcase/referral/referral.component';
import {AddUpdateReferral} from "./components/navcase/referral/referral.addupdate";
import {ViewScreeningComponent} from "./components/screening/view.screening.component";
import {ScreeningDetailsComponent} from "./components/screening/details.screening";
import {NavigatorListComponent} from "./components/navigator/navigator.list.component";
import {NavigatorComponent} from "./components/navigator/navigator.component";
import {AddupdateNavigatorComponent} from "./components/navigator/addupdate.navigator.component";

export const interceptorProviders = [{provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}];

const appRoutes: Routes = [
  { path: LOGIN_PATH, component: LoginComponent},
  { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: SITE_PATH, component: HomeComponent, canActivate: [Authguard]},
  { path: SEARCH_BENEFICIARY_HOME, component: SearchHomeComponent, canActivate: [Authguard]},
  { path: BENEFICIARY_PATH, component: BeneficiaryComponent/*, canActivate: [Authguard]*/}, 
  { path: SCREENING_PATH, component: ScreeningComponent, canActivate: [Authguard]},
  { path: SCREENING_CONSENT_PATH, component: ConsentComponent, canActivate: [Authguard]},
  { path: SCREENING_REGISTRATION_PATH, component: RegistrationComponent, canActivate: [Authguard]},
  { path: SEARCH_BENEFICIARY_PATH, component: SearchComponent, canActivate: [Authguard]},
  { path: SEARCH_BENEFICIARY_LIST, component: SearchListComponent, canActivate: [Authguard]},
  { path: SCREENING_RESULT_PATH, component: ResultComponent /*, canActivate: [Authguard] */},
  { path: USER_MANAGEMENT, component: UserComponent },
  { path: NEW_USER_PWD, component: PasswordComponent},
  { path: RESET_USER_PWD, component: PasswordComponent},
  { path: RESET_PASSWORD, component : PasswordResetComponent},
  { path: NAVIGATION_LIST, component: NavigatorListComponent},
  { path: '**', redirectTo: '' }
];

@NgModule({
  declarations: [
    AppComponent, LoginComponent, SearchHomeComponent, HomeComponent, ScreeningComponent, SectionComponent, QuestionComponent,
    ResponseComponent, NavComponent, ConsentComponent, RegistrationComponent, ResultComponent,
    SearchComponent, SearchListComponent, BeneficiaryComponent, UpdateBeneficiaryComponent, EventComponent, NeedComponent, NeedAddupdateComponent,
    AddEventComponent, MergeBeneficiary, UserComponent, ManageUserComponent, PasswordComponent, PasswordResetComponent, ConfirmDialog, ReferralComponent, AddUpdateReferral,
    ViewScreeningComponent, ScreeningDetailsComponent, NavigatorListComponent, NavigatorComponent, AddupdateNavigatorComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, {enableTracing: false}), BrowserModule, BrowserAnimationsModule, MatDialogModule, MatPaginatorModule, FormsModule,
    ReactiveFormsModule, HttpClientModule, MatTableModule, MatCheckboxModule, MatRadioModule, MatSelectModule, MatAutocompleteModule, MatInputModule, MatExpansionModule, MatGridListModule
  ],
  entryComponents: [
    UpdateBeneficiaryComponent, NeedAddupdateComponent, AddEventComponent, MergeBeneficiary, ManageUserComponent, ConfirmDialog, AddUpdateReferral, ScreeningDetailsComponent, AddupdateNavigatorComponent
  ],
  providers: [interceptorProviders, UrlService, SystemManager, EncryptionService, ScreeningDataService, SiteService, AppErrorHandler, Authguard,
    AuthenticationService, SearchService, BeneficiaryDataService, BeneficiaryService, IdleLogoutService, DialogSystemManager, ],

  bootstrap: [AppComponent]
})
export class AppModule { }

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UrlService} from "../../../system/url.service";
import {headers} from "../../../model/app.constants";
import {catchError, map} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {CaseNeed} from "../../../model/app.model";
import {error} from "util";


@Injectable()

export class CaseNeedService {

  constructor(private http: HttpClient, private systemService: UrlService) {
  }

  public getCaseNeedsByCase(caseId: string) {
    let url = this.systemService.caseNeedBaseUrl + '?caseId=' + caseId;
    return this.http.get(url, {headers: headers, observe: "response"}).pipe(map((response: any) => {
      if(response.status != 200){
        throwError("Error occured getting needs for this case");
      } else {
        return response;
      }
    }), catchError( error => {
      throw error;
    }));
  }
  public submitNewNeed(need : CaseNeed) {
    let url = this.systemService.caseNeedBaseUrl;
    return this.http.put(url, need, {headers : headers, observe : 'response'}).pipe(map((response:any) =>{
      if(response.status != 201){
        throwError('Error occured while adding Case Need');
      } else {
        return response['body'];
      }
    }), catchError( error => {
      throw error;
    }));
  }
  public updateNeed(need : CaseNeed) {
    let url = this.systemService.caseNeedBaseUrl;
    return this.http.post(url, need, {headers : headers, observe : 'response'}).pipe(map((response : any)=>{
      if(response.status != 200){
        throwError('Error occured while updating Case Need');
      } else {
        return response['body'];
      }
    }), catchError( error => {
      throw error;
    }));
  }
}

import {Injectable} from "@angular/core";
//import * as data from '../../assets/config/envConfig.json';
import {throwError} from "rxjs";
import {HttpBackend, HttpClient} from "@angular/common/http";

@Injectable()
export class UrlService {

  private http;
  private domain : string = '';
  private basePath : string = '/ahc/api';
  private baseEndpoint : string = '';
  //resource directory endpoint
  private rdBaseEndpoint : string = '';
  private rdBaseUIEndpoint : string = '';

  constructor(private handler : HttpBackend){
    //we don't want this local http request to be intercept by http interceptor to avoid circular reference so using HttpBackend to bypass interceptor
    this.http = new HttpClient(this.handler);
    let url = 'assets/config/envConfig.json';
    let me = this;
    this.http.get(url).subscribe(data =>{
      me.domain = data['domain'];
      me.baseEndpoint = me.domain + me.basePath;
      me.rdBaseEndpoint = data['resourceDirectoryUrl'];
      me.rdBaseUIEndpoint = data['resourceDirectoryUiUrl'];

    }, error1 => {
      throwError(error1);
    });

  }

  public get appDomain():string {
    return this.domain;
  }

  public get loginUrl() : string {
    return this.baseEndpoint + '/login';
  }

  public get beneficiaryScreeingUrl() {
    return this.baseEndpoint + '/beneficiaryscreening/beneficiaryscreenings';
  }

  public get newScreeningUrl() {
    return this.baseEndpoint + '/screening/screenings';
  }

  public get siteBaseUrl(){
    return this.baseEndpoint + '/site/sites';
  }

  public get beneficiaryBaseUrl(){
    return this.baseEndpoint + '/beneficiary/beneficiaries';
  }

  public get navigationCaseBaseUrl(){
    return this.baseEndpoint + '/navigationcase/navigationcases';
  }

  public get caseNeedBaseUrl(){
    return this.baseEndpoint + '/caseneed/caseneeds';
  }

  public get caseEventBaseUrl() {
    return this.baseEndpoint + '/caseevent/caseevents';
  }
  public get mergeBeneficiaryUrl(){
    return this.baseEndpoint + '/merge/merges/beneficiaries';
  }
  public get userUrl(){
    return this.baseEndpoint + '/user/users';
  }
  public get signupUrl(){
    return this.baseEndpoint + '/user/users/sign-up';
  }
  public get referralUrl(){
    return this.baseEndpoint + '/referral/referrals';
  }

  public get navigatorUrl(){
    return this.baseEndpoint + '/navigator/navigators';
  }

  public get resourceDirLookahaedUrl(){
    return this.rdBaseEndpoint + '/lookahead';
  }
  public get resourceDirSearchUrl(){
    return this.rdBaseEndpoint + '/search_needs';
  }
  public get resourceDirLocationUrl(){
    return this.rdBaseEndpoint + '/locations';
  }

  public get resourceDirUIUrl(){
    return this.rdBaseUIEndpoint;
  }

}

import {Component, OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AuthenticationService} from "../login/authentication.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {ScreeningDataService} from "../../helpers/screening.dataservice";
import {Beneficiary} from "../../model/app.model";
import {
  SCREENING_REGISTRATION_PATH,
  SEARCH_BENEFICIARY_PATH,
  BENEFICIARY_PATH,
  SEARCH_BENEFICIARY_HOME
} from "../../model/app.constants";
import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";
import {MatDialog, MatDialogRef} from "@angular/material";
import {MergeBeneficiary} from "../beneficiary/merge.beneficiary";
import {clone} from "../../helpers/common.function";



@Component({
  selector: 'beneficiary-search-result',
  templateUrl : './search.list.component.html',
  styleUrls: []

})
export class SearchListComponent implements OnInit, OnDestroy{
  subscription : Subscription;
  beneficiaries : Beneficiary[];
  displayColumns : string [] = ['lastName', 'firstName', 'middleName', 'dob', 'address', 'select'];
  mergingBeneficiaries : Beneficiary[] = new Array<Beneficiary>();
  selectionForm : FormGroup;
  selectMerge : boolean = false;
  dialogueRef : MatDialogRef<any, any> = null;
  constructor(protected authService : AuthenticationService, protected router : Router, private dataService : ScreeningDataService, private benDataService : BeneficiaryDataService,
              private builder : FormBuilder, private dialogue : MatDialog){
    this.selectionForm = builder.group({selection : new FormControl('')});
  }

  select(beneficiary) {
    this.benDataService.setBeneficiary(beneficiary);
    this.router.navigate([BENEFICIARY_PATH]).then();
  }

  public toggleSelection(beneficiary, event){
    if(this.selectMerge){
      if(!event.checked){
        this.removeFromMergable(beneficiary)
      } else {
        if(this.mergingBeneficiaries.length < 2){
          let alreadySelected = false;
          for(let i = 0; i < this.mergingBeneficiaries.length; i++){
            let temp = this.mergingBeneficiaries[i];
            if(temp.id === beneficiary.id){
              alreadySelected = true;
            }
          }
          if(!alreadySelected){
            this.mergingBeneficiaries.push(beneficiary);
          } else {
            event.source.checked = false;
          }
        } else {
          event.source.checked = false;
        }
      }
    }
  }
  private removeFromMergable(beneficiary : Beneficiary){
    if(this.mergingBeneficiaries.length > 0){
      for(let i = 0; i < this.mergingBeneficiaries.length; i++){
        let temp = this.mergingBeneficiaries[i];
        if(temp.id === beneficiary.id){
          this.mergingBeneficiaries.splice(i, 1);
          break;
        }
      }
    }
  }

  public openMergeModal(){
    const me = this;
    let tempSubscription : Subscription = null;
    let candidates = clone(this.mergingBeneficiaries);
    this.dialogueRef = this.dialogue.open(MergeBeneficiary, { height: 'auto', width: '75%', data: {"beneficiaries" : candidates}});
    tempSubscription = this.dialogueRef.afterOpen().subscribe(data=>{
      me.toggleMerge();
      this.mergingBeneficiaries = new Array<Beneficiary>();

    }, error1 => {

    }, ()=>{
      tempSubscription.unsubscribe();
    });

  }
  public new(){
    let beneficiary = new Beneficiary();
    let tempBenficiary = this.benDataService.getSelectedBeneficiary();
    if(tempBenficiary){
      beneficiary.firstName = tempBenficiary.firstName;
      beneficiary.lastName = tempBenficiary.lastName;
      beneficiary.dob = tempBenficiary.dob;
      this.benDataService.setBeneficiary(beneficiary);
    }
    this.dataService.setBackRoute(SEARCH_BENEFICIARY_HOME);
    this.router.navigate([SCREENING_REGISTRATION_PATH]).then();
  }

  public back(){
    this.router.navigate([this.dataService.getBackRoute()]).then();
  }

  public toggleMerge(){
    this.selectMerge = !this.selectMerge;
    if(this.selectMerge){
      this.displayColumns.unshift('merge');
    }
    else {
      this.displayColumns.shift();
    }
  }

  ngOnInit() {
    let temp = this.benDataService.getSearchResultBeneficiaries();
    if(temp && temp.length > 0){
      this.beneficiaries = temp;
    }
    this.dataService.setBackRoute(SEARCH_BENEFICIARY_PATH);
    this.benDataService.beneficiariesResult.subscribe(foundBeneficiaries => {
      this.beneficiaries = foundBeneficiaries;
      if(this.selectMerge){
        this.toggleMerge();
      }
    });
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}

import {Injectable, OnDestroy} from "@angular/core";
import * as crypto from "crypto-js"


@Injectable()
export class EncryptionService implements OnDestroy {

  constructor(){
  }

  key : string = undefined;

  public encrypt (json : any): any {
    if(this.key != undefined){
      if(json){
        var encrypted =  crypto.AES.encrypt(json, this.key);
        return encrypted;
      }
    }
    return undefined;
  }

  public decrypt(json : any) : any {
    if(this.key != undefined){
      if(json){
        var bytes = crypto.AES.decrypt(json, this.key);
        var decrypted = bytes.toString(crypto.enc.Utf8);
        return decrypted;
      }
    }
    return undefined;
  }

  public setKey(key : string) : void {
    this.key = key;
  }

  public getKey() : string {
    return this.key;
  }

  ngOnDestroy() {
    this.key = undefined;
  }

}

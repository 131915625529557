import {Injectable} from "@angular/core";
import {UrlService} from "../../../system/url.service";
import {HttpClient} from "@angular/common/http";
import {headers} from "../../../model/app.constants";
import {catchError, map} from "rxjs/operators";
import {Referral} from "../../../model/app.model";

//main referral service shared by referral and add/update referral components

@Injectable()

export class ReferralService {

  constructor(private urlService : UrlService, private http : HttpClient){
  }

  /*
    In this class we use SKIP_HEADER in some places instead of the usual headers variable we use since RD call is external and
    we need to skip adding JWT credentials to outgoing request in our interceptor.
   */

  public fetchReferrals(beneId : string){
    let url = this.urlService.referralUrl + '/beneficiaries/' + beneId;
    return this.http.get(url, {headers : headers, observe : 'response'}).pipe(map((response: any)=>{
      return response;
    }), catchError(err => {throw err}));
  }
  public fetchReferral(id : string){
    let url = this.urlService.referralUrl + '/beneficiaries/' + id;
    return this.http.get(url, {headers : headers, observe : 'response'}).pipe(map((response: any)=>{
      return response;
    }), catchError(err => {throw err}));
  }

  public addReferral(referral : Referral){
    let url = this.urlService.referralUrl;
    return this.http.put(url, referral, {headers : headers, observe : 'response'}).pipe(map((response: any)=>{
      return response;
    }), catchError(err => {throw err}));
  }

  public updateReferral(referral : Referral){
    let url = this.urlService.referralUrl;
    return this.http.post(url, referral, {headers : headers, observe : 'response'}).pipe(map((response: any)=>{
      return response;
    }), catchError(err => {throw err}));
  }

  public lookup(searchTerm : string){
    let url = this.urlService.resourceDirLookahaedUrl + '?name=' + searchTerm;
    return this.http.get(url).pipe(map((response:any) => {
      return response;
    }), catchError(err=>{throw err}));
  }

  public lookupServices(id : string){
    let url = this.urlService.resourceDirLocationUrl + '/' + id;
    return this.http.get(url).pipe(map((response:any) => {
      return response;
    }), catchError(err=>{throw err}));
  }

}

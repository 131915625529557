import {UserService} from "./user.service";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LOGIN_PATH} from "../../model/app.constants";
import {isEmpty} from "../../helpers/common.validator";


@Component({
  selector: 'app-password',
  templateUrl : './password.manage.html',
  providers : [UserService]
})

export class PasswordComponent implements OnInit, OnDestroy{
  isNew : boolean = false;
  isReset : boolean = false;
  tokenId : string;
  token : string;
  passwordForm : FormGroup;
  submitted : boolean = false;
  hasError : boolean = false;
  errorMessage : string = null;
  constructor(private userService : UserService, private route : ActivatedRoute, private builder: FormBuilder, private router : Router){
    this.passwordForm = builder.group({password : new FormControl('', [Validators.required]), confirmPassword : new FormControl('', [Validators.required])});
  }

  get password(){
    return this.passwordForm.get('password');
  }
  get confirmPassword(){
    return this.passwordForm.get('confirmPassword');
  }

  private isValid(){
    let valid = true;
    if(isEmpty(this.password)){
      this.password.setErrors({'message' : 'Password is required'});
      valid = false;
    }
    if(isEmpty(this.confirmPassword)){
      this.confirmPassword.setErrors({'message' : 'Confirm password is required'});
      valid = false;
    }
    if(!isEmpty(this.password) && this.password.value.length < 10){
      this.password.setErrors({'message' : 'Password must be at least 10 characters long'});
      valid = false;
    }
    if(this.password.value != this.confirmPassword.value){
      this.password.setErrors({'message' : 'Password do not match'});
      valid = false;
    }
    if(valid){
      this.password.setErrors(null);
      this.confirmPassword.setErrors(null);
    }
    return valid;
  }

  protected onKey($event){

  }
  private reset(){
    this.hasError = false;
    this.errorMessage = null;
    this.submitted = false;
    this.password.setErrors(null);
    this.confirmPassword.setErrors(null);
  }

  private encodePassword() {
    let password = this.password.value;
    if(password.indexOf("&") > -1){
      let sign = encodeURIComponent("&");
      password = password.replace(/&/g, sign);
    }
    if(password.indexOf("+") > -1){
      let sign = encodeURIComponent("+");
      password = password.replace(/\+/g, sign);
    }
    return password;

  }
  public submitPassword(){
    this.reset();
    this.submitted = true;
    if(this.isValid()){
      let me = this;
      let password = me.encodePassword();
      this.userService.managePassword(me.tokenId, me.token, password).subscribe(data=>{

      }, error1 => {
        me.hasError = true;
        me.errorMessage = error1['error'];
      }, ()=>{
        me.hasError = false;
        me.errorMessage = null;
        this.router.navigate([LOGIN_PATH]).then();
      });
    }

  }

  ngOnInit() {
    this.isNew = this.route.snapshot.paramMap.get('new')==="new";
    this.isReset = this.route.snapshot.paramMap.get('reset')==="reset";
    this.tokenId = this.route.snapshot.queryParamMap.get('id');
    this.token = this.route.snapshot.queryParamMap.get('token');
  }

  ngOnDestroy(){

  }
}

import {Injectable} from "@angular/core";
import {UrlService} from "../../system/url.service";
import {HttpClient} from "@angular/common/http";
import {headers} from "../../model/app.constants";
import {catchError, map} from "rxjs/operators";

@Injectable()

export class NavigatorService {
  constructor(private urlService : UrlService, private http : HttpClient){
  }

  public fetchNavigators(){
    let url = this.urlService.navigatorUrl;
    return this.http.get(url, {headers : headers, observe : 'response'}).pipe(map((response : any)=>{return response}),
      catchError(err => {throw err})
    );
  }
  public fetchNavigatorsByBeneId(beneId : string){
    let url = this.urlService.navigatorUrl + '/beneficiary/' + beneId;
    return this.http.get(url, {headers : headers, observe : 'response'}).pipe(map((response : any)=>{return response}),
      catchError(err => {throw err}));
  }

  public attachNavigator(userId : string, beneId : string){
    let url = this.urlService.navigatorUrl + '/users/' + userId + '/beneficiaries/' + beneId;
    return this.http.post(url, undefined, {headers : headers, observe: 'response'}).pipe(map((response:any)=>{
      return response;
    }),
      catchError(err => {throw err}));
  }
  public updateNavigator(navId : string, status : string){
    let url = this.urlService.navigatorUrl  + '/' + navId + '?status=' + status;
    return this.http.post(url, undefined, {headers : headers, observe: 'response'}).pipe(map((response:any)=>{
        return response;
      }),
      catchError(err => {throw err}));
  }

}

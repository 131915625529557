import {checkLimit, checkMaxLength, isValueEmpty} from "./common.validator";
import {Address, AhcFormControl, Beneficiary, HrsnType} from "../model/app.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {icons} from "../model/app.constants";

export function padId(value: string) {
  if(!isValueEmpty(value)){
    let len = value.length;
    if(len < 9){
      let more = 9 - len;
      for(let i = 0; i < more; i++){
        value = '0' + value;
      }
    }
  }
  return value;
}

export function getISoDateString(year, month, day) : string {
  let date = new Date(year, month-1, day);
  date.setUTCHours(0, 0, 0, 0);
  return date.toISOString();
}

export function getLocalDateInISOFormat() : string{

  let date = new Date();
  let dd = date.getDate().toString();
  let yyyy = date.getFullYear().toString();
  let mm = (date.getMonth()+1).toString();
  let hh = date.getHours().toString();
  let MM = date.getMinutes().toString();

  let month = mm.length==1?'0'+mm:mm;
  let day = dd.length==1?'0'+dd:dd;
  let hours = hh.length==1?'0'+hh:hh;
  let minutes = MM.length==1?'0'+MM:MM;
  let localDate = yyyy+'-'+month+'-'+day+'T'+hours+':'+minutes;
  return localDate;
}

export function deformatPhone(phone : string) : string{
  let formattedPhone = phone;
  if(phone && phone.length > 0){
    formattedPhone = phone.replace(/[\s\(\)\-\.]/g, '');
  }
  return formattedPhone;
}

export function clone(data: any) {
  let cloned = JSON.stringify(data);
  let clonedObject = JSON.parse(cloned);
  return clonedObject;
}

export function createBeneficiaryForm (beneficiary : Beneficiary, builder : FormBuilder) : FormGroup {
  beneficiary.address? null : beneficiary.address = new Address();

  let currentYear = new Date().getFullYear();
  let monthMessage = "Month must be between 1 and 12";
  let dayMessage = "Day must be between 1 and 31";
  let yearMessage = "Year must be greater than 1900 and less than " + currentYear;
  //extract date components
  let dob = beneficiary.dob?new Date(beneficiary.dob):null;
  let medicaidDate = beneficiary.medicaidEffctDate? new Date(beneficiary.medicaidEffctDate) : null;
  let medicareDate = beneficiary.medicareEffctDate? new Date(beneficiary.medicareEffctDate) : null;
  let hicnDate = beneficiary.hicnEffctDate? new Date(beneficiary.hicnEffctDate) : null;
  let form = builder.group({
    firstName: new AhcFormControl(beneficiary.firstName, Validators.required, null,'First name is required', 'First Name'),
    lastName: new AhcFormControl(beneficiary.lastName, Validators.required, null, 'Last name is required', 'Last Name'),
    middleName : new AhcFormControl(beneficiary.middleName, null, null, null, 'Middle Name'),
    hcamId: new AhcFormControl(beneficiary.hcamId, null, null, null, 'HCAM Id'),
    month: new FormControl(dob ? dob.getUTCMonth()+1 : '', [Validators.required, checkMaxLength(2), checkLimit(1, 12, monthMessage)]),
    day: new FormControl(dob ?dob.getUTCDate() : '', [Validators.required, checkMaxLength(2), checkLimit(1, 31, dayMessage)] ),
    year: new FormControl(dob ? dob.getUTCFullYear() : '', [Validators.required, checkMaxLength(4), checkLimit(1900, currentYear, yearMessage)]),
    medicaidId: new AhcFormControl(beneficiary.medicaidId, null, null, 'Medicaid Id'),
    medicaidMonth: new FormControl(medicaidDate ? medicaidDate.getUTCMonth()+1 : '' ),
    medicaidDay: new FormControl(medicaidDate ? medicaidDate.getUTCDate() :'' ),
    medicaidYear: new FormControl(medicaidDate ? medicaidDate.getUTCFullYear() :''),
    medicareId: new AhcFormControl(beneficiary.medicareId, null, null, 'Medicare Beneficiary ID (MBI)'),
    medicareMonth: new FormControl(medicareDate ? medicareDate.getUTCMonth()+1 :''),
    medicareDay: new FormControl(medicareDate ? medicareDate.getUTCDate() :''),
    medicareYear: new FormControl(medicareDate ? medicareDate.getUTCFullYear() :''),
    hicn : new AhcFormControl(beneficiary.hicn, null, null, 'Health Insurance Claim Number (HICN)'),
    hicnMonth: new FormControl(hicnDate ? hicnDate.getUTCMonth()+1 :''),
    hicnDay: new FormControl(hicnDate ? hicnDate.getUTCDate():''),
    hicnYear: new FormControl(hicnDate ? hicnDate.getUTCFullYear() :''),
    primaryPhone : new AhcFormControl(beneficiary.primaryPhone, null, null, 'Primary phone number'),
    primaryPhoneType : new AhcFormControl(beneficiary.primaryPhoneType, null, null, 'Primary phone type'),
    secondaryPhone : new AhcFormControl(beneficiary.secondaryPhone, null, null, 'Secondary phone'),
    secondaryPhoneType : new AhcFormControl(beneficiary.secondaryPhoneType, null, null, 'Secondary phone type'),
    gender : new AhcFormControl(beneficiary.gender, null, null, 'Gender'),
    email: new AhcFormControl(beneficiary.email, null, null, 'Email format is incorrect', 'Email'),
    address: builder.group({
      addrLine1: new AhcFormControl(beneficiary.address.addrLine1, Validators.required, null, 'Address must be provided', 'Address Line 1'),
      addrLine2 : new AhcFormControl(beneficiary.address.addrLine2, null, null, 'Address Line 2'),
      city: new AhcFormControl(beneficiary.address.city, Validators.required, null, 'City must be provided', 'City'),
      state: new AhcFormControl(beneficiary.address.state, Validators.required, null, 'State must be provided', 'State'),
      zip: new AhcFormControl(beneficiary.address.zip, Validators.required, null, 'Zip must be provided', 'Zip code'),
      zipExtension : new AhcFormControl(beneficiary.address.zipExtension, null, null, 'Zip code 4-digit extension')

    })
  });
  return form;
}

export function getIcon(label) : string {
  let value = HrsnType[label];
  return icons[value];
}

export function getIconFromString(label) : string {
  return icons[label];
}

export function toAddressString(address : Address) : string {
  if(address){
    let str = address.addrLine1 + ', ' + address.city + ', ' + address.zip;
    return str;
  }
  return '';
}

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UrlService} from "../../system/url.service";
import {headers} from "../../model/app.constants";
import {map} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {BinaryIndicator, NavigationCase} from "../../model/app.model";

@Injectable()

export class NavigationCaseService {
  constructor(private http: HttpClient, private systemService : UrlService){}

  public getNavCasesForBeneficiary(beneId : string){
    let url = this.systemService.navigationCaseBaseUrl + '/beneficiaries/' + beneId;
    return this.http.get(url, {headers:headers, observe:'response'}).pipe(map((response : any)=>{
      if(response.status != 200){
        throwError("Error occured getting navigation case");
      } else {
        return response['body'];
      }
    }));
  }
  public getNavigationCase(beneId : string) : Observable<NavigationCase> {
    let me = this;
    return this.getNavCasesForBeneficiary(beneId).pipe(map((response : any ) => {
      if(response){
        let cases = response as Array<NavigationCase>;
        if(cases && cases.length > 0){
          return me.determineOpenCase(cases);
        }
      }
    }));
  }

  public submitConsent(id : string, consent : boolean){
    let url = this.systemService.navigationCaseBaseUrl + "/" + id + "?consent=" + consent;
    return this.http.post(url, null,{headers : headers, observe: 'response'}).pipe(map((respponse : any)=>{
      if(respponse.status != 200){
        throwError("Error occured submitting consent");
      } else {
        return respponse['body'];
      }
    }));
  }

  private determineOpenCase(cases : Array<NavigationCase>) : NavigationCase{
    let tempNavCase : NavigationCase = null;
    for(let i = 0; i < cases.length; i++){
      let navCase = cases[i];
      /*
          A case may not be open because it yet has to receive consent from beneficiary to be opened in which case
          effective and term dates will be null as well as opt out flag.
         */
      if(navCase.beneOptOut === null){
        tempNavCase = navCase;
        break;
      } else if (this.isCaseOpen(navCase)){
        tempNavCase = navCase;
        break;
      } else {
        //case is consented and close which also needed to display content
        tempNavCase = navCase;
        break;
      }
    }
    return tempNavCase;
  }
  public isCaseOpen(navCase : NavigationCase):boolean{
    let isOpen : boolean = false;
    if(navCase){
      /*
        get present time and the compare with case termination time to determine if case is open
        ONLY compare dates and not time
       */
      let todayWithTime = new Date();
      let today = new Date(todayWithTime.getFullYear(), todayWithTime.getMonth(), todayWithTime.getDate());
      //get time since Jan 1 1970
      let localTimeMs = today.getTime();
      //convert to UTC - 1000 ms = 1 sec, 1 minute = 60 secs. total: 60000 to get in ms unit
      let localOffsetMs = today.getTimezoneOffset() * 60000;
      let presentInUTC = localTimeMs + localOffsetMs;
      //get case termination date and convert to UTC and compare
      if(navCase.caseTermDate){
        let termDateWithTime = new Date(navCase.caseTermDate);
        let termDate = new Date(termDateWithTime.getFullYear(), termDateWithTime.getMonth(), termDateWithTime.getDate());
        let localEndTimeMs = termDate.getTime();
        let utcEndTime = localEndTimeMs + localOffsetMs;
        if(presentInUTC <= utcEndTime){
          isOpen = true;
        }
      }
    }
    return isOpen;
  }

  public isCaseConsented (navCase : NavigationCase) : boolean {
    if(navCase){
      if(navCase.beneOptOut.toString() === "Y") {
        return false;
      }
    }
    return true;
  }

  // (1) Navigation case created, not yet consented
  public isCaseOpenNotTouched(navCase : NavigationCase) : boolean {
    if(navCase){
      if(navCase.beneOptOut === null && navCase.caseEffctDate === null && navCase.caseTermDate === null){
        return true;
      }
      return false;
    }
    return false;
  }
  //(2) Patient consents/nav case opened (term dt set to today + 365 days)
  public isCaseOpenConsented(navCase : NavigationCase) : boolean {
    if(navCase){
      if(this.isCaseOpen(navCase)){
        if(navCase.beneOptOut && navCase.beneOptOut.toString() === "N"){
          return true;
        }
        return false;
      }
    }
    return false;
  }

  //(3) Patient consents/nav case closed (term dt passed) - for now, won't show needs or events on closed case
  public isCaseClosedConsented(navCase : NavigationCase) : boolean {
    if(navCase){
      if(navCase.beneOptOut && navCase.beneOptOut.toString() === "N"){
        if(!this.isCaseOpen(navCase)){
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }

  //(4) Patient does not consent/nav case closed (term dt set to today/no needs display)
  public isCaseClosedAndOptedOut(navCase : NavigationCase) : boolean {
    if(navCase){
      if(!this.isCaseOpen(navCase)){
        if(navCase.beneOptOut && navCase.beneOptOut.toString() === "Y" ){
          return true;
        }
        return false;
      }
    }
    return false;
  }

}

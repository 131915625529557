import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {UserService} from "./user.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Authority, Role, Site, User} from "../../model/app.model";
import {isEmpty, isValueEmpty} from "../../helpers/common.validator";
import {SiteService} from "../../helpers/site.service";
import {Subscription} from "rxjs";
import {DialogSystemManager} from "../../system/dialog.system.manager";

@Component({
  selector : '',
  templateUrl : './user.manage.html',
  providers : [UserService]
})

export class ManageUserComponent implements OnInit, OnDestroy {
  userForm : FormGroup;
  isUpdate : boolean = false;
  hasError : boolean = false;
  errorMessage : string = null;
  submitted : boolean = false;
  roles : Array<string>;
  savedUser : User;
  sites : Array<Site>;
  subscription : Subscription;
  selectedUser: User = new User();
  disableSubmitted : boolean = false;
  deleteSubmitted : boolean = false;
  selectedRoles : string[];
  sysAdmin : string = 'SYSADMIN';
  reportUser : string = 'REPORTUSER';
  isDisabled : boolean = false;
  PARTNER : string = 'PARTNER';
  constructor(private builder : FormBuilder, public dialogRef : MatDialogRef<ManageUserComponent>, @Inject(MAT_DIALOG_DATA) public data : any,
              private userService : UserService, private siteService : SiteService, private dialogManager : DialogSystemManager){
    //register with dialog manager so that if dialog is open at timout time then it can be closed before user being logged out
    this.dialogManager.register('ManagerUserComponent', this.dialogRef);
    this.selectedRoles = new Array<string>();
    this.isUpdate = data.isUpdate;
    if(this.isUpdate){
      this.selectedUser = data['user'];
      if(this.selectedUser.authorities){
        for(let i = 0; i < this.selectedUser.authorities.length; i++){
          let roleString = this.selectedUser.authorities[i].authority.toString();
          if(roleString != this.sysAdmin && roleString != this.reportUser){
            this.selectedRoles.push(roleString);
            //if partner is the role selected then disable others
            if(roleString === this.PARTNER){
              this.isDisabled = true;
            }
          }
        }
      }
    }
    this.userForm = this.builder.group({firstName : new FormControl(this.selectedUser.firstName, []),
      lastName : new FormControl(this.selectedUser.lastName, [Validators.required]),
      email : new FormControl(this.selectedUser.email, [Validators.required]),
      siteName: new FormControl(this.selectedUser.siteName, [Validators.required]),
      authorities : new FormControl(this.selectedRoles, [Validators.required]),
      reason : new FormControl('')});
    this.roles = [];
    let list = Object.values(Role).sort();
    for(let i = 0; i < list.length; i++){
      let roleStr = list[i];
      if(roleStr != this.sysAdmin && roleStr != this.reportUser){
        this.roles.push(roleStr);
      }
    }
  }

  onSelectRoles(event){
    let array = event.value;
    let filtered = array.filter(role => role === 'PARTNER');
    if(filtered.length > 0){
      this.authorities.setValue(['PARTNER']);
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  }

  get firstName(){
    return this.userForm.get('firstName');
  }
  get lastName(){
    return this.userForm.get('lastName');
  }
  get email(){
    return this.userForm.get('email');
  }

  get siteName(){
    return this.userForm.get('siteName');
  }

  get authorities(){
    return this.userForm.get('authorities');
  }
  get reason(){
    return this.userForm.get('reason');
  }

  onKey(event){}

  private reset(){
    this.submitted = false;
    this.hasError = false;
    this.errorMessage = null;
  }

  private isValid(){
    let valid = true;

    if(isValueEmpty(this.firstName.value)){
      valid = false;
      this.firstName.setErrors({'message' : 'First name is required'});
    }
    if(isValueEmpty(this.lastName.value)){
      valid = false;
      this.lastName.setErrors({'message' :'Last name is required'});
    }
    if(isValueEmpty(this.email.value)){
      valid = false;
      this.email.setErrors({'message' : 'Email is required'});
    }
    if(isValueEmpty(this.siteName.value)){
      valid = false;
      this.siteName.setErrors({'message' : 'Site name is required'});
    }
    if(this.authorities.value.length === 0){
      valid = false;
      this.authorities.setErrors({'message' : 'At least one role must be specified'});
    }
    if(valid){
      this.firstName.setErrors(null);
      this.lastName.setErrors(null);
      this.email.setErrors(null);
      this.siteName.setErrors(null);
      this.authorities.setErrors(null);
    }
    return valid;
  }

  private createUser() : User {
    let user = new User();
    user.firstName = this.firstName.value;
    user.lastName = this.lastName.value;
    user.email = this.email.value;
    user.siteName = this.siteName.value;
    let array = this.authorities.value as Array<string>;
    let authoritiesArray = new Array<Authority>();
    for(let i = 0; i < array.length; i++){
      let authority = new Authority();
      authority.authority = array[i];
      authoritiesArray.push(authority);
    }
    user.authorities = authoritiesArray;
    return user;
  }

  public inviteUser(){
    this.submitted = true;
    if(this.isValid()){
      let me = this;
      let user = this.createUser();
      me.subscription = me.userService.addUser(user).subscribe(response =>{
        me.savedUser = response['body'];
      }, error1 => {
        me.hasError = true;
        me.errorMessage = error1['error'];
      }, ()=>{
        me.hasError = false;
        me.errorMessage = null;
        me.submitted = false;
        me.dialogRef.close({"saved" : true, "user" : me.savedUser});
      });
    }

  }

  private createUpdatedUser(){
    let user = this.selectedUser;
    user.firstName = this.firstName.value;
    user.lastName = this.lastName.value;
    user.email = this.email.value;
    user.siteName = this.siteName.value;
    let array = this.authorities.value as Array<string>;
    let authoritiesArray = new Array<Authority>();
    for(let i = 0; i < array.length; i++){
      let authority = new Authority();
      authority.authority = array[i];
      authoritiesArray.push(authority);
    }
    user.authorities = authoritiesArray;
    return user;
  }

  public updateUser(){
    this.submitted = true;
    if(this.isValid()){
      let me  = this;
      let user = this.createUpdatedUser();
      let sub = this.userService.updateUser(user).subscribe(data=>{
        me.savedUser = data['body'] as User;
      }, error1 => {
        me.hasError = true;
        me.errorMessage = error1.error;
      }, ()=>{
        me.hasError = false;
        me.errorMessage = null;
        this.submitted = false;
        sub.unsubscribe();
        me.dialogRef.close({"saved" : true, "user" : me.savedUser});
      });
    }
  }

  protected deleteUser(){
    this.deleteSubmitted = true;
    this.disableSubmitted = false;
  }

  private delete(){
    if(isEmpty(this.reason)){
      this.reason.setErrors({'message' : 'A reason must be supplied when deleting an account'});
      return;
    }
    this.reason.setErrors(null);
    let reason = this.reason.value;
    let me = this;
    let sub = this.userService.deleteUser(this.selectedUser.email, reason).subscribe(data=>{

    }, error1 => {
      me.hasError = true;
      me.errorMessage = error1['error']?error1['error']:'error occured deleting accoun';
    }, ()=>{
      sub.unsubscribe();
      me.hasError = false;
      me.errorMessage = null;
      this.reset();
      this.deleteSubmitted = false;
      me.dialogRef.close({"deleted" : true, "user" : me.selectedUser});
    });
  }

  public disable(){
    this.disableSubmitted = true;
    this.deleteSubmitted = false;
  }

  public submitForDisabling(){
    this.reset();
    this.disableSubmitted = false;
    let me = this;
    let sub = me.userService.disableUser(me.selectedUser.id).subscribe(data=>{
      me.selectedUser = data as User;
    }, error1 => {
      me.hasError = true;
      me.errorMessage = error1.error;
    }, ()=>{
      sub.unsubscribe();
      me.hasError = false;
      me.errorMessage = null;
      me.dialogRef.close({"saved" : true, "user" : me.selectedUser});
    });
  }

  public enableUser(){
    let me = this;
    let sub = me.userService.enableUser(me.selectedUser.id).subscribe(data=>{
      me.selectedUser = data as User;
    }, error1 => {
      me.hasError = true;
      me.errorMessage = error1.error;
    }, ()=>{
      sub.unsubscribe();
      me.hasError = false;
      me.errorMessage = null;
      me.dialogRef.close({"saved" : true, "user" : me.selectedUser});
    });
  }
  private fetchSites(){
    let me = this;
    me.siteService.fetchSites();
    me.subscription = me.siteService.siteData.subscribe(data=>{
      me.sites = data as Array<Site>;
    });
  }
  ngOnInit() {
    this.fetchSites();
    if(this.isUpdate){
      if(!this.selectedUser.enabled){
        this.userForm.disable({onlySelf: true, emitEvent: false});
      }
    }
  }
  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ScreeningService} from "../../helpers/screening.service";
import {first} from "rxjs/operators";
import {Subscription} from "rxjs/internal/Subscription";
import {BeneficiaryScreening, Screening, Section, Source} from "../../model/app.model";
import {ScreeningDataService} from "../../helpers/screening.dataservice";
import {BENEFICIARY_PATH, SCREENING_RESULT_PATH} from "../../model/app.constants";
import {Router} from "@angular/router";

import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";

@Component({
  selector: 'app-screening',
  templateUrl: './screening.component.html',
  providers: [
    ScreeningService
  ],
  styleUrls: []
})
export class ScreeningComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  error = undefined;
  hasError: boolean = false;
  beneficiaryScreening:BeneficiaryScreening;
  selectedSection:Section = new Section();
  currentIndex = 0;
  hasSaved : boolean = false;
constructor(private screeningSvc : ScreeningService, private dataService : ScreeningDataService, private benDataService : BeneficiaryDataService, private router : Router) {
    this.hasSaved = false;
    this.reset();
}


  /*
  @HostListener('window:beforeunload')
  goToLogin(){
    this.router.navigate([LOGIN_PATH]);

  }*/

  reset(){
    this.error = undefined;
    this.hasError = false;
    this.beneficiaryScreening = new BeneficiaryScreening();
    this.beneficiaryScreening.source = Source.UI;
    this.beneficiaryScreening.screening = new Screening();

  }

  public onSelectingSection(event) : void {
    this.switchSelection(event);
    this.save();
  }

  private switchSelection(section) : void {
    for(let i = 0; i < this.beneficiaryScreening.screening.sections.length; i++){
      let tempSection = this.beneficiaryScreening.screening.sections[i];
      if(tempSection.id === section.id){
        tempSection.selected = true;
        this.selectedSection = tempSection;
        this.currentIndex = i;
      } else {
        tempSection.selected = false;
      }
    }
    window.scroll(0,0);
  }

  private save(){
    const me = this;
    if(me.hasSaved){
      me.subscription = this.screeningSvc.submitScreening(me.beneficiaryScreening.id, me.beneficiaryScreening.screening).pipe(first()).subscribe(data=>{
        let savedScreening = data['body'];
        me.dataService.setBeneficiaryScreening(savedScreening);
        me.beneficiaryScreening = me.dataService.getBeneficiaryScreening();
        me.selectedSection = me.beneficiaryScreening.screening.sections[me.currentIndex];
        me.selectedSection.selected = true;
      }, error => {
        me.hasError = true;
        me.error = error;
      }, () => {

      });
    } else {
      this.subscription = this.screeningSvc.saveScreening(this.beneficiaryScreening).subscribe(data => {
        me.beneficiaryScreening = data['body'];
        me.dataService.setBeneficiaryScreening(me.beneficiaryScreening);
      }, error1 => {
        me.hasError = true;
        me.error = error1;
      }, () => {
        me.hasSaved = true;
      });
    }

  }

  private saveAndMoveForward(){
    const me = this;
    if(me.hasSaved){
      this.subscription = this.screeningSvc.submitScreening(this.beneficiaryScreening.id, this.beneficiaryScreening.screening).pipe(first()).subscribe(data=>{
        let savedScreening = data['body'];
        me.dataService.setBeneficiaryScreening(savedScreening);
        me.beneficiaryScreening = this.dataService.getBeneficiaryScreening();
      }, error => {
        this.hasError = true;
        this.error = error;
      }, () => {
        me.moveNext();
      });
    } else {
      this.subscription = this.screeningSvc.saveScreening(this.beneficiaryScreening).subscribe(data => {
        me.beneficiaryScreening = data['body'];
        me.dataService.setBeneficiaryScreening(me.beneficiaryScreening);
      }, error1 => {
        this.hasError = true;
        this.error - error1;
      }, () => {
        me.hasSaved = true;
        me.moveNext();
      });
    }

  }

  private saveAndMoveBack(){
    const me = this;
    if(me.hasSaved){
      this.subscription = this.screeningSvc.submitScreening(this.beneficiaryScreening.id, this.beneficiaryScreening.screening).pipe(first()).subscribe(data=>{
        let savedScreening = data['body'];
        me.dataService.setBeneficiaryScreening(savedScreening);
        me.beneficiaryScreening = me.dataService.getBeneficiaryScreening();

      }, error => {
        me.hasError = true;
        me.error = error;
      }, () => {
        me.hasSaved = true;
        me.moveBack();
      });
    } else {
      this.subscription = this.screeningSvc.saveScreening(this.beneficiaryScreening).subscribe(data => {
        me.beneficiaryScreening = data['body'];
        me.dataService.setBeneficiaryScreening(me.beneficiaryScreening);
        me.hasSaved = true;
      }, error1 => {
        this.hasError = true;
        this.error - error1;
      }, () => {
        me.hasSaved = true;
        me.moveBack();
      });
    }

  }

  public back() : void {
    this.saveAndMoveBack();
  }

  private moveBack() : void {
    if(this.currentIndex > 0){
      let section = this.beneficiaryScreening.screening.sections[this.currentIndex-1];
      this.switchSelection(section);
    } else {
      this.router.navigate([this.dataService.getBackRoute()]).then();
    }
  }
  private moveNext() : void {
    if(this.currentIndex + 1 === this.beneficiaryScreening.screening.sections.length) {
      this.router.navigate([SCREENING_RESULT_PATH]).then();
    } else {
      let section = this.beneficiaryScreening.screening.sections[this.currentIndex+1];
      this.switchSelection(section);
    }
  }
  public next() : void {
    this.saveAndMoveForward();
  }

  public exit() : void {
    this.router.navigate([BENEFICIARY_PATH]).then();
  }
  
  ngOnInit() {
    window.scroll(0,0);
    this.currentIndex = 0;
    let me = this;
    this.subscription = this.screeningSvc.getNewScreening().subscribe(data => {
      let screening = data['body'];
      me.dataService.setScreening(screening);
      me.beneficiaryScreening = new BeneficiaryScreening();
      me.beneficiaryScreening.source = Source.UI;
      me.beneficiaryScreening.screening = screening;
      me.beneficiaryScreening.site = me.dataService.getSite();
      me.beneficiaryScreening.beneficiary = me.benDataService.getSelectedBeneficiary();
      me.beneficiaryScreening.consented = true;
      me.selectedSection = me.beneficiaryScreening.screening.sections[me.currentIndex];
      me.selectedSection.selected = true;
      me.dataService.setBeneficiaryScreening(me.beneficiaryScreening);
    }, error1 => {
      this.error = error1;
      this.hasError = true;
    });

  }

  ngOnDestroy() {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}

import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "./authentication.service";
import {Subscription} from "rxjs/internal/Subscription";
import {first} from "rxjs/operators";
import {Router} from "@angular/router";
import {
  LOGIN_FAILURE_MESSAGE,
  RESET_PASSWORD,
  SEARCH_BENEFICIARY_HOME,
} from "../../model/app.constants";
import {SystemManager} from "../../system/system.manager";


@Component({
  selector : 'login-app',
  templateUrl: 'login.component.html',
  providers: [

  ]
})
export class LoginComponent implements OnDestroy, OnInit {

  loginForm: FormGroup;
  subscription: Subscription;
  hasError: boolean = false;
  errorMessage:string = '';
  submitted : boolean = false;

  constructor(private authService: AuthenticationService, private formBuilder: FormBuilder, private router : Router, private sysManager : SystemManager){
    this.createForm();
  }

  createForm(){
    this.loginForm = this.formBuilder.group({username: new FormControl('', Validators.required), password: new FormControl('', Validators.required)});
  }

  reset(){
    this.hasError = false;
    this.errorMessage = '';
    this.submitted = false;
  }

  login() : void {
    this.hasError = false;
    this.subscription = this.authService.login(this.formFields.username, this.formFields.password).pipe(first()).subscribe(data=>{
      //console.log("Status: " + data['status']);
      let status = data['status'];
      if(status === 200){
        this.router.navigate([SEARCH_BENEFICIARY_HOME]).then();
      }

    }, errorResponse=> {
      this.hasError = true;
      if(errorResponse.status && errorResponse.status === 401){
        this.errorMessage = errorResponse['error']['message'];
      }
      else {
        this.errorMessage = errorResponse;
      }

    });

  }
  public resetPassword(){
    this.router.navigate([RESET_PASSWORD]).then();
  }

  get formFields() { return this.loginForm.value;}
  get username() { return this.loginForm.get('username');}
  get password() {return this.loginForm.get('password');}

  onSubmit(){
    this.reset();
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.login();

  }


  ngOnInit(){
    this.sysManager.reset();
  }

  ngOnDestroy() {
    if(this.subscription){
      this.subscription.unsubscribe();
    }

  }

}


import {Component} from "@angular/core";
import {RegistrationComponent} from "../registration/registration.component";
import {ScreeningService} from "../../helpers/screening.service";
import {BeneficiaryService} from "./beneficiary.service";
import {ScreeningDataService} from "../../helpers/screening.dataservice";
import {Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {AuthenticationService} from "../login/authentication.service";
import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";
import {MatDialogRef} from "@angular/material";
import {createBeneficiaryForm} from "../../helpers/common.function";
import {DialogSystemManager} from "../../system/dialog.system.manager";

@Component({
  selector : '',
  templateUrl : './update.beneficiary.html',
  providers: [ScreeningService]
})

export class UpdateBeneficiaryComponent extends RegistrationComponent {

  constructor(protected beneficiaryService : BeneficiaryService, protected dataService : ScreeningDataService, protected router : Router,
              protected builder: FormBuilder, protected authService : AuthenticationService,
              protected benDataService : BeneficiaryDataService, public dialogRef : MatDialogRef<UpdateBeneficiaryComponent>, private dialogManager : DialogSystemManager){
    super(dataService , router, builder, authService, benDataService);
    this.dialogManager.register('UpdateBeneficiaryComponent', this.dialogRef);
  }

  createBeneficiaryForm() {
    this.beneficiaryForm = createBeneficiaryForm(this.beneficiary, this.builder);
  }
  public onSubmitForm() {
    let me = this;
    me.reset();
    me.submitted = true;
    if (me.isValid()) {
      let beneficiary = me.createBeneficiary();
      me.subscription = me.beneficiaryService.updateBeneficiary(beneficiary).subscribe(response =>{
        let beneficiary = response['body'];
        if(beneficiary){
          me.benDataService.setBeneficiary(beneficiary);
        }
      }, error1 => {}, ()=>{me.dialogRef.close();});
    }
  }

  ngOnDestroy(){
    //unsubscribe if subscribing
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
}

import {EventEmitter, Injectable, Output} from "@angular/core";
import {Beneficiary} from "../model/app.model";
import {BENEFICIARY_DATA_SERVICE} from "../model/app.constants";
import {SystemManager} from "../system/system.manager";


@Injectable()
export class BeneficiaryDataService implements OnReset{
  private searchResultBeneficiaries : Beneficiary[];
  private selectedBeneficiary : Beneficiary;

  @Output() beneficiariesResult: EventEmitter<Beneficiary[]>;

  constructor(private systemManager : SystemManager){
    this.beneficiariesResult = new EventEmitter();
    let me = this;
    this.systemManager.addService(me);
  }

  public reset(){
    this.searchResultBeneficiaries = null;
    this.selectedBeneficiary = null;
  }

  setSearchResultBeneficiaries(beneficiaries : Beneficiary[]){
    this.searchResultBeneficiaries = beneficiaries;
    this.beneficiariesResult.emit(this.searchResultBeneficiaries)
  }

  setBeneficiary(beneficiary : Beneficiary){
    this.selectedBeneficiary = beneficiary;
  }
  getSearchResultBeneficiaries() : Beneficiary[] {
    return this.searchResultBeneficiaries;
  }

  getSelectedBeneficiary():Beneficiary{
    return this.selectedBeneficiary;
  }

  OnReset(){
    this.reset();
  }

  getName(){
    return BENEFICIARY_DATA_SERVICE;
  }

}

import {AsyncValidatorFn, FormControl, ValidatorFn} from "@angular/forms";


export class AhcObject {
  id: string
}

export class Person extends AhcObject {
  firstName : string;
  lastName : string;
  middleName : string;
}

export enum Role {
  ADMIN = "ADMIN",
  NAVIGATOR = "NAVIGATOR",
  REPORTUSER = "REPORTUSER",
  SYSADMIN = "SYSADMIN",
  PARTNER = "PARTNER"
}

export enum UserStatus {
  ACTIVE,
  DISABLED,
  INVITED
}

export class Authority{
  authority : string;
}

export class User extends AhcObject{
  constructor(){super();}
  firstName: string;
  lastName: string;
  email : string;
  userStatus : UserStatus;
  authorities: Array<Authority>;
  siteName : string;
  enabled : boolean;
}

export class ScreeningResponse implements AhcObject{
  id:string;
  response: string;
  selected: boolean;
  explanation: string;
  hasNotes : boolean;
  notes : string;
  points : number;
}

export class ScreeningQuestion implements AhcObject{
  id : string;
  question : string;
  sequence : string;
  responses : Array<ScreeningResponse>;
  multipleChoice : boolean = false;
  answered : boolean = false;
  public getScreeningResponseById(id) : ScreeningResponse {
    let tempScreeningResp : ScreeningResponse = null;
    if(this.responses && this.responses.length > 0){
      for(var i = 0; i < this.responses.length; i++){
        let response = this.responses[i];
        if(response.id === id){
          tempScreeningResp = response;
          break;
        }
      }
    }
    return tempScreeningResp;
  }
}

export class Section {
  id:string;
  label : string;
  description : string;
  questions : Array<ScreeningQuestion>;
  sequence : string;
  selected : boolean = false;
  answered : boolean = false;
  need : BinaryIndicator;

  public getQuestionById(id) : ScreeningQuestion {
    let tempquestion:ScreeningQuestion = null;
    if(this.questions && this.questions.length > 0){
      for(let i = 0; i < this.questions.length; i++){
        let question = this.questions[i];
        if(question.id === id){
          tempquestion = question;
          break;
        }
      }
    }
    return tempquestion;
  }

}

export class Screening implements AhcObject{
  id:string;
  sections: Array<Section>
  public getSectionById(id) : Section {
    let tempSection: Section = null;
    if (this.sections && this.sections.length > 0) {
      for (var i = 0; i < this.sections.length; i++) {
        var section = this.sections[i];
        if (section.id === id) {
          tempSection = section;
          break;
        }
      }
    }
    return tempSection;
  }
}

export class Beneficiary {
  id : string;
  dateCreated:number;
  firstName: string;
  middleName : string;
  lastName: string;
  medicaidId : string;
  medicaidEffctDate : string;
  medicareId : string;
  medicareEffctDate : string;
  hicn : string;
  hicnEffctDate : string;
  crispId : string;
  dob : string;
  gender : string;
  primaryPhone : string;
  primaryPhoneType : string;
  secondaryPhone : string;
  secondaryPhoneType : string;
  email : string;
  hcamId : string;
  address : Address;
  screeningIds : string[];
}

export class Address {
  name : string;
  addrLine1 : string;
  addrLine2 : string;
  city : string;
  state : string;
  zip : string;
  zipExtension : string;

  toString() {
    let str = this.addrLine1 + ', ' + this.city + ', ' + this.zip;
    return str;
  }

}

export class Site {
  name: string;
  address: Address;
  snsId : string;
  screeningSite : boolean;
}

export enum BinaryIndicator {
  N, Y,
}

export enum RiskCategory {
  NO_RISK, LOW_RISK, HIGH_RISK,

}

export class RiskIndicator {
  erVisitsPositive: boolean;
  socialNeedPositive: boolean;
  communityDwelling: BinaryIndicator;
  coreDomainsPositive: boolean;
}

export class BeneficiaryScreening {
  id : string;
  beneficiary: Beneficiary;
  screening: Screening;
  site : Site;
  consented : boolean = false;
  bchdRiskCategory : RiskCategory;
  riskCategory : RiskCategory;
  dateCreated : string;
  createdBy : string;
  riskIndicator : RiskIndicator;
  highRisk : BinaryIndicator;
  source : Source;
}

export enum Source {
  UI = "UI",
  CRISP = "CRISP",
  CMS = "CMS"
}
export class NavigationCase {
  id : string;
  dateCreated : string;
  dateUpdated : string;
  beneScreeningId : string;
  beneId : string;
  beneRegOrgId : string;
  origSnsId : string;
  updatingSnsId : string;
  beneOptOut : BinaryIndicator;
  optOutDate : string;
  notes : string;
  caseEffctDate : string;
  caseTermDate : string;

}

export enum ReferralStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED"

}
export class Referral extends AhcObject {
  beneficiaryId : string;
  orgName: string;
  serviceType : string;
  needs : Array<HrsnType>;
  referralStatus : ReferralStatus;
  openDate : string;
  closeDate : string;
  estimatedCost : number;
  notes : string;
  url:string;
  routed : boolean;
  routedTo : string
}

export class AhcFormControl extends FormControl {
  invalidMessage : string;
  label : string;
  constructor(formState : any, validatorOrOpts? : ValidatorFn, asyncValidator? : AsyncValidatorFn, invalidMessage? : string, label? : string){
    super(formState, validatorOrOpts, asyncValidator);
    this.invalidMessage = invalidMessage;
}
}

export class CaseNeed {
  id : string;
  dateCreated : string;
  caseId : string;
  hrsnType: HrsnType;
  encounterType : EncounterType;
  resolutionType : ResolutionType;
  statusCode : StatusCode;
  eventNeedEffctDate : string;
  eventNeedTermDate : string;

}

export enum HrsnType {
  EMPLOYMENT = "Employment",
  FAMILY_COMMUNITY_SUPPORT = "Family Community Support",
  EDUCATION = "Education",
  PHYSICAL_ACTIVITY = "Physical Activity",
  SUBSTANCE_USE = "Substance Use",
  LIVING_SITUATION = "Living Situation" ,
  FOOD = "Food",
  TRANSPORTATION = "Transportation" ,
  UTILITIES = "Utilities" ,
  SAFETY = "Safety",
  FINANCIAL = "Financial Strain" ,
  MENTAL_HEALTH = "Mental Health",
  DISABILITY = "Disability",

}

export enum EncounterType {
  INITIAL_SCREENING = "Initial Screening",
  ADDITIONAL_SCREENING = "Additional Screening",
  FOLLOW_UP = "Follow Up"
}

export enum ResolutionType {
  RESOLVED = "Resolved",
  SUCCESSFUL = "Successful",
  UNAVAILABLE = "Unavailable",
  ATTEMPT_FAILED = "Attempt Failed",
  OPTOUT = "Optout",
  RESOLUTION_PENDING = "Resolution Pending"
}

export enum StatusCode {
  RESOLVED = "Resolved",
  UNRESOLVED = "Unresolved",
  IN_PROGRESS = "In Progress"
}

export enum ServiceType {
  CONTACT_ATTEMPT_FAILED = "Contact Attemp Failed",
  PERSONAL_INTERVIEW_ACTION_PLAN = "Interview Action Plan",
  FOLLOW_UP = "Follow Up",
  OTHER = "Other"
}

export enum DurationCode {
  FIVE_MIN_FOLLW = "Five Minute Followup",
  FIFTEN_MIN_FOLLW = "Fifteen Minute Followup",
  THIRTY_MIN_FOLLW = "Thirty Minute Followup",
  THIRTY_MRE_FOLLW = "Thirty More Minutes Followup"
}

export enum ProviderType {
  COMMUNITY_HEALTH_WORKER = "Community Health Worker",
  NURSE_PRACTITIONER = "Nurse Practitioner",
  PHYSICIAN_ASSISTANT = "Physician Assistant",
  MEDICAL_OFFICE_ASSISTANT = "Medical Office Assistant",
  SOCIAL_WORKER = "Social Worker",
  PHYSICIAN = "Physician",
  OTHER = "Other"
}

export class CaseEvent {
  id : string;
  dateCreated : string;
  caseId : string;
  comments : string;
  serviceType : ServiceType;
  durationCode : DurationCode;
  encounterType : EncounterType;
  providerType : ProviderType;

}

export enum Status {
  ACTIVE = "ACTIVE",
  INACTVIE = "INACTIVE"
}

export class Navigator extends AhcObject {
  dateCreated : string;
  firstName : string;
  lastName : string;
  middleName : string;
  navigatorSite : string;
  userId : string;
  username : string;
  navigationSite : string;
  beneId : string;
  beneficiaryName : string;
  status : Status;

}

import {Injectable} from "@angular/core";
import {BeneficiaryScreening, Screening, Site} from "../model/app.model";
import {SCREENING_DATA_SERVICE, SITE_PATH} from "../model/app.constants";
import {SystemManager} from "../system/system.manager";

@Injectable()
export class ScreeningDataService implements OnReset{
  private beneficiaryScreening : BeneficiaryScreening;
  private screening : Screening;
  private site : Site;

  private backRoute : string = SITE_PATH;

  constructor(private systemManager : SystemManager){
    let me = this;
    this.systemManager.addService(me);
  }

  public reset() : void {
    this.beneficiaryScreening = null;
    this.screening = null;
    this.site = null;
    this.backRoute = SITE_PATH;
  }

  setBackRoute(route : string){
    this.backRoute = route;
  }
  getBackRoute() : string{
    return this.backRoute;
  }
  setScreening(data : Screening){
    this.screening = data;
  }
  setBeneficiaryScreening(data : BeneficiaryScreening){
    this.beneficiaryScreening = data;
  }
  setSite(data : Site){
    this.site = data;
  }



  getBeneficiaryScreening() : BeneficiaryScreening {
    return this.beneficiaryScreening;
  }
  getQuestionaire() : Screening {
    return this.screening;
  }
  getSite() : Site {
    return this.site;
  }

  OnReset(){
    this.reset();
  }
  getName(){
    return SCREENING_DATA_SERVICE;
  }


}

import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AUTH_HEADER, SITE_SERVICE} from "../model/app.constants";
import {map} from "rxjs/operators";
import {throwError} from "rxjs/internal/observable/throwError";
import {Site} from "../model/app.model";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {UrlService} from "../system/url.service";
import {SystemManager} from "../system/system.manager";

@Injectable()
export class SiteService implements OnReset{
  private siteDataSource = new ReplaySubject<any>();
  public siteData = this.siteDataSource.asObservable();
  public hasData = false;
  constructor(private systemService : UrlService, private systemManager: SystemManager, private http : HttpClient) {
    this.hasData = false;

  }

  public fetchSites(){
      let url = this.systemService.siteBaseUrl;
      this.http.get(url, {headers: new HttpHeaders({ 'Content-Type': 'application/json'}).append('Access-Control-Expose-Headers', AUTH_HEADER),
        observe: 'response' }).subscribe(response => {
        if(response){
          if(response.status != 200){
            throwError(response['message']);
          }

          let sites = response['body'] as Array<Site>;
          sites.sort((siteA : Site, siteB : Site) => siteA.name.localeCompare(siteB.name));
          this.siteDataSource.next(sites);
          this.hasData = true;
        }
      });
  }

  OnReset(){
    this.hasData = false;
    this.siteDataSource = new ReplaySubject<any>();
    this.siteData = this.siteDataSource.asObservable();
  }
  getName(){
    return SITE_SERVICE;
  }

}

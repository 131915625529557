import {Component, OnDestroy, OnInit} from "@angular/core";
import {UserService} from "./user.service";
import {Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {isEmpty} from "../../helpers/common.validator";
import {LOGIN_PATH} from "../../model/app.constants";


@Component({
  selector: 'app-reset-password',
  templateUrl : './password.reset.html',
  providers : [UserService]
})

export class PasswordResetComponent implements OnInit, OnDestroy{
  resetForm : FormGroup;
  submitted : boolean = false;
  hasError : boolean = false;
  errorMessage : string = null;
  success : boolean = false;
  successMessage : string = null;
  constructor(private userService : UserService, private router : Router, private builder : FormBuilder){
    this.resetForm = builder.group({email : new FormControl('', [Validators.required])});
  }

  get email(){
    return this.resetForm.get('email');
  }

  private isValid(){
    let valid = true;
    this.hasError = false;
    this.errorMessage = null;
    if(isEmpty(this.email)){
      valid = false;
      this.email.setErrors({'invalid' : true});
    }
    else {
      this.email.setErrors(null);
    }
    return valid;
  }

  public gotoLogin(){
    this.router.navigate([LOGIN_PATH]).then();
  }

  public submitEmail(){
    if(this.isValid()){
      let me = this;
      let sub = this.userService.resetPassword(this.email.value).subscribe(data=>{}, error1 => {
        me.hasError = true;
        me.errorMessage = error1.error;
      }, ()=>{
        sub.unsubscribe();
        me.success = true;
        me.successMessage = 'An email has been sent to the email address you provided - please click below to go back to login page';
        me.email.disable();
      })
    }
  }
  ngOnInit() {

  }

  ngOnDestroy(){

  }

}

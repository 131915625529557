import {SearchService} from "./search.service";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {
  LOGIN_PATH, SCREENING_CONSENT_PATH
} from "../../model/app.constants";
import {AuthenticationService} from "../login/authentication.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs/internal/Subscription";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {checkLimit, checkMaxLength, isEmpty, isNumber} from "../../helpers/common.validator";
import {DatePipe} from "@angular/common";
import {Beneficiary} from "../../model/app.model";
import {ScreeningDataService} from "../../helpers/screening.dataservice";
import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";

@Component({
  selector: 'beneficiary-search',
  templateUrl: './search.component.html',
  styleUrls: [],
  providers: [SearchService, DatePipe]
})
export class SearchComponent implements OnInit, OnDestroy {
  subscription : Subscription;
  beneficiarySearchForm : FormGroup;
  beneficiaries = null;
  beneficiary = null;
  hasError : boolean = false;
  error = null;
  submitted : boolean = false;
  currentYear = new Date().getFullYear();
  monthMessage = "Month must be between 1 and 12";
  dayMessage = "Day must be between 1 and 31";
  yearMessage = "Year must be greater than 1900 and less than " + this.currentYear;

  constructor(private searchService : SearchService, private authService : AuthenticationService,
              private router : Router, private formBuilder : FormBuilder, private datePipe : DatePipe, private dataService : ScreeningDataService, private benDataService : BeneficiaryDataService){

    this.beneficiary = this.benDataService.getSelectedBeneficiary();
    let firstName = this.beneficiary?this.beneficiary.firstName:'';
    let lastName = this.beneficiary?this.beneficiary.lastName:'';
    let month = this.beneficiary?new Date(this.beneficiary.dob).getUTCMonth()+1:'';
    let day = this.beneficiary?new Date(this.beneficiary.dob).getUTCDate():'';
    let year = this.beneficiary?new Date(this.beneficiary.dob).getUTCFullYear():'';

    this.beneficiarySearchForm = this.formBuilder.group({
      firstName : new FormControl(firstName), lastName : new FormControl(lastName),
      month: new FormControl(month, [Validators.required, checkMaxLength(2), checkLimit(1, 12, this.monthMessage)]),
      day: new FormControl(day, [Validators.required, checkMaxLength(2), checkLimit(1, 31, this.dayMessage)] ),
      year: new FormControl(year, [Validators.required, Validators.max(4), checkMaxLength(4), checkLimit(1900, this.currentYear, this.yearMessage)]),});
  }

  onKey(event){

  }

  public findBeneficiary() : void {
    const me = this;
    this.submitted = true;
    if(this.isValid()){
      let firstName = this.firstName.value;
      let lastName = this.lastName.value;
      let day = this.day.value;
      let month = this.month.value;
      let year = this.year.value;
      let dob = this.datePipe.transform(new Date(year, month-1, day), 'MM/dd/yyyy');
      let beneficiary = new Beneficiary();
      beneficiary.firstName = firstName;
      beneficiary.lastName = lastName;
      beneficiary.dob = dob;
      this.benDataService.setBeneficiary(beneficiary);
      this.subscription = this.searchService.search(firstName, lastName, dob).subscribe( data => {
        me.beneficiaries = data['body'];
        me.benDataService.setSearchResultBeneficiaries(me.beneficiaries);
        //me.router.navigate([SEARCH_BENEFICIARY_LIST]).then();
      }, error1 => {
        me.hasError = true;
        me.error = error1;
      });
    }

  }

  private isValid(){
    let valid = true;
    if(this.day.invalid){
      valid = false;
    }
    if(!isNumber(this.day.value)){
      this.day.setErrors({"message" : "Day must be a number"});
      valid = false;
    } else {
      this.day.setErrors(null);
    }

    if(!isNumber(this.month.value)){
      this.month.setErrors({"message" : "Month must be a number"});
      valid = false;
    }else {
      this.month.setErrors(null);
    }
    if(!isNumber(this.year.value)){
      this.year.setErrors({"message" : "Year must be a number"});
      valid = false;
    }else {
      this.year.setErrors(null);
    }
    return valid;
  }

  get firstName() {
    return this.beneficiarySearchForm.get('firstName');
  }

  get lastName() {
    return this.beneficiarySearchForm.get('lastName');
  }

  get day() {
    return this.beneficiarySearchForm.get('day');
  }

  get month(){
    return this.beneficiarySearchForm.get('month');
  }

  get year() {
    return this.beneficiarySearchForm.get('year');
  }

  public back(){
    this.router.navigate([this.dataService.getBackRoute()]).then();
  }

  ngOnInit() {

    if(!this.authService.isLoggedIn()){
      this.router.navigate([LOGIN_PATH]).then();
    } else {
      this.dataService.setBackRoute(SCREENING_CONSENT_PATH);
    }
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}

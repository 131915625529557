import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {BeneficiaryScreening, Screening} from "../../model/app.model";
import {getIcon, getIconFromString} from "../../helpers/common.function";
import {DialogSystemManager} from "../../system/dialog.system.manager";

@Component({
  selector: 'app-details-screening',
  templateUrl : './details.screening.html'
})

export class ScreeningDetailsComponent {
  private beneficiaryScreening : BeneficiaryScreening;
  private screening : Screening;
  constructor(private dialogManager : DialogSystemManager, private dialogRef : MatDialogRef<ScreeningDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data : any){
    this.dialogManager.register('ScreeningDetailsDialog', this.dialogRef);
    this.beneficiaryScreening = data['beneficiaryScreening'];
    this.screening = this.beneficiaryScreening.screening;
  }

  public getIcon(label) : string {
    let icon = getIconFromString(label);
    return icon;
  }

}

import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ScreeningQuestion, Section} from "../../../model/app.model";
import {icons} from "../../../model/app.constants";

@Component({
  selector: 'screening-section',
  templateUrl: 'section.component.html'
})

export class SectionComponent {
  private icons = icons;
  @Input() section : Section;
  @Output() sectionClick : EventEmitter<Section> = new EventEmitter<Section>();

  constructor(){

  }


public getIcon(label) : string {
    return this.icons[label];
}

  public setSelected() : void {
    this.section.selected = true;
    this.sectionClick.emit(this.section);
  }

}

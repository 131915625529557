import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {ReferralService} from "./referral.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HrsnType, Referral, ReferralStatus} from "../../../model/app.model";
import {DatePipe} from "@angular/common";
import {isEmpty} from "../../../helpers/common.validator";
import {Subscription} from "rxjs";
import {UrlService} from "../../../system/url.service";
import {DialogSystemManager} from "../../../system/dialog.system.manager";


//add referral modal component
@Component({
  selector: 'app-addupdate-referral',
  templateUrl: './referral.addupdate.html',
  providers : [ReferralService, DatePipe]
})
export class AddUpdateReferral implements OnInit, OnDestroy{

  isUpdate : boolean = false;
  referralForm : FormGroup;
  hasError : boolean = false;
  errorMessage : string = null;
  submitted : boolean = false;
  title : string = "Referral";
  socialNeeds : Array<string>;
  selectedNeeds : Array<string> = new Array<string>();
  statuses : Array<string>;
  referral : Referral;
  orgNames : any[] = [];
  serviceTypes : any[] = [];
  orgNameSelected : boolean = false;
  selectedOrg : Array<any> = new Array<any>();
  slug : string;
  subscriptions : Array<Subscription> = new Array<Subscription>();

  constructor(private referralService : ReferralService, public dialogRef : MatDialogRef<AddUpdateReferral>,
              @Inject(MAT_DIALOG_DATA) public data : any, private builder : FormBuilder, private datePipe : DatePipe, private urlService : UrlService, private dialogManager : DialogSystemManager){

    this.dialogManager.register('AddUpdateReferral', this.dialogRef);
    //populate data based on update/add mode
    this.statuses = Object.keys(ReferralStatus);
    this.isUpdate = data['isUpdate'];
    if(this.isUpdate){
      this.referral = data['referral'] as Referral;
      this.serviceTypes.push(this.referral.serviceType);
      this.referral.openDate = datePipe.transform(this.referral.openDate, "yyyy-MM-dd", 'UTC');
      if(this.referral.closeDate){
        this.referral.closeDate = datePipe.transform(this.referral.openDate, "yyyy-MM-dd", 'UTC');
      }
      //selected needs contain existing needs for this referral. social needs in this case will contain the remaining ones
      if(this.referral.needs && this.referral.needs.length > 0){
        for(let i = 0; i < this.referral.needs.length; i++){
          let need = this.referral.needs[i];
          this.selectedNeeds.push(HrsnType[need]);
        }
      }
      this.socialNeeds = Object.values(HrsnType).filter(value => { return !this.selectedNeeds.includes(value)});
    } else {
      //add mode
      this.referral = new Referral();
      this.referral.beneficiaryId = data['beneId'];
      this.referral.referralStatus = ReferralStatus.OPEN;
      this.referral.openDate = datePipe.transform(new Date(), "yyyy-MM-dd");
      this.socialNeeds = Object.values(HrsnType).sort();
    }
    this.referralForm = builder.group({
      orgName : new FormControl(this.referral.orgName, [Validators.required]),
      serviceType : new FormControl(this.referral.serviceType, [Validators.required]),
      needs : new FormControl(this.selectedNeeds, [Validators.required]),
      referralStatus : new FormControl(this.referral.referralStatus, [Validators.required]),
      estimatedCost : new FormControl(this.referral.estimatedCost), notes: new FormControl(this.referral.notes),
      openDate : new FormControl(this.referral.openDate), closeDate: new FormControl(this.referral.closeDate), url : new FormControl(this.referral.url), routed : new FormControl(this.referral.routed),
      routedTo : new FormControl(this.referral.routedTo)
    });
  }

  get orgName(){
    return this.referralForm.get('orgName');
  }
  get serviceType(){
    return this.referralForm.get('serviceType');
  }
  get needs(){
    return this.referralForm.get('needs');
  }
  get referralStatus(){
    return this.referralForm.get('referralStatus');
  }
  get openDate(){
    return this.referralForm.get('openDate');
  }
  get closeDate(){
    return this.referralForm.get('closeDate');
  }
  get esitmatedCost(){
    return this.referralForm.get('estimatedCost');
  }
  get notes(){
    return this.referralForm.get('notes');
  }
  get url(){
    return this.referralForm.get('url');
  }
  get routed() {
    return this.referralForm.get('routed');
  }
  get routedTo() {
    return this.referralForm.get('routedTo');
  }
  protected selectStatus(event){
    if(event.value === 'CLOSED'){
      this.closeDate.setValue(this.datePipe.transform(new Date(), "yyyy-MM-dd"));
      this.closeDate.enable();
    } else {
      this.closeDate.setValue(null);
      this.closeDate.disable();
    }
  }

  private createReferral() : Referral {
    let needs = this.needs.value as Array<HrsnType>;
    if(needs && needs.length > 0){
      this.referral.needs = new Array<HrsnType>();
      for(let i = 0; i < needs.length; i++){
        let hrsn = Object.keys(HrsnType).find(k => HrsnType[k] === needs[i]);
        this.referral.needs.push(hrsn as HrsnType);
      }
    }
    //in add mode org name control has array as value, in update it's string
    this.referral.orgName = (this.isUpdate ? this.orgName.value : this.orgName.value[0]);
    this.referral.estimatedCost = this.esitmatedCost.value;
    this.referral.notes = this.notes.value;
    this.referral.closeDate = this.closeDate.value;
    this.referral.referralStatus = this.referralStatus.value;
    this.referral.serviceType = this.serviceType.value;
    this.referral.url = this.url.value;
    this.referral.routed = this.routed.value;
    this.referral.routedTo = this.routedTo.value;
    return this.referral;
  }

  private isValid() : boolean {
    let valid = true;
    this.orgName.setErrors(null);
    this.serviceType.setErrors(null);
    this.needs.setErrors(null);
    this.referralStatus.setErrors(null);
    this.openDate.setErrors(null);
    if(isEmpty(this.orgName)){
      this.orgName.setErrors({'message' : "Organization name is required"});
      valid = false;
    }
    if(isEmpty(this.serviceType)){
      this.serviceType.setErrors({'message' : 'Service type is required'});
      valid = false;
    }
    if(isEmpty(this.needs)){
      this.needs.setErrors({'message' : 'Need is required'});
      valid = false;
    }
    if(isEmpty(this.referralStatus)){
      this.referralStatus.setErrors({'message' : 'Referral status is required'});
      valid = false;
    }
    if(isEmpty(this.openDate)){
      this.openDate.setErrors({'message' : 'Open date is required'});
      valid = false;
    }
    return valid;
  }


  public save(){
    if(this.isUpdate){
      this.update();
    } else {
      this.add();
    }
  }

  public add(){
    this.submitted = true;
    let me = this;
    if(this.isValid()){
      let referral = this.createReferral();
      let subscription = this.referralService.addReferral(referral).subscribe(data => {
        me.referral = data['body'] as Referral;
      }, error1 => {
        me.hasError = true;
        me.errorMessage = error1['error'];
      }, ()=>{
        subscription.unsubscribe();
        me.dialogRef.close({'referral' : me.referral});
      });
    }
  }

  public update(){
    this.submitted = true;
    let me = this;
    if(this.isValid()){
      let referral = this.createReferral();
      let subscription = this.referralService.updateReferral(referral).subscribe(data => {
        me.referral = data['body'] as Referral;
      }, error1 => {
        me.hasError = true;
        me.errorMessage = error1['error'];
      }, ()=>{
        subscription.unsubscribe();
        me.dialogRef.close({'referral' : me.referral});
      });
    }
  }

  onKey(event){
    //if user presses key that means user is typing values in org name field. reset flag to allow lookup
    this.orgNameSelected = false;
  }

  onOrgNameSelected(event){
    if(!this.isUpdate){
      let me = this;
      me.serviceTypes = [];
      me.orgNameSelected = true;
      me.selectedOrg = event.source.value;
      //selected org is an array of [name:id]. e.g. ["food services" : 123]
      me.orgName.setValue(this.selectedOrg[0]);
      let subscription = me.referralService.lookupServices(me.selectedOrg[1]).subscribe(data =>{
        let temp = data['services'] as any[];
        me.slug = data['slug'];
        if(temp && temp.length > 0){
          let array = temp.map((val, ind, arr)=>{
            return val.name;
          });
          me.serviceTypes = array as any[];
        }
      }, error1 => {subscription.unsubscribe();}, ()=>{
        subscription.unsubscribe();
        //create url for this referral
        me.url.setValue(me.slug?me.urlService.resourceDirUIUrl + 'locations/' + me.slug:'');
      });
    }
  }

  onRouted(event){
    let checked = event.checked;
    if(checked){
      this.routedTo.enable();
    } else {
      this.routedTo.setValue(null);
      this.routedTo.disable();
    }
  }

  ngOnInit() {
    this.openDate.disable();
    this.closeDate.disable();
    if(!this.routed.value){
      this.routedTo.disable();
    }
    if(this.isUpdate){
      this.serviceType.disable();
      if(this.referral.referralStatus === ReferralStatus.CLOSED.toString()){
        this.esitmatedCost.disable();
        this.needs.disable();
        this.referralStatus.disable();
        this.routed.disable();
        this.routedTo.disable();
      }
    } else {
      let me = this;
      let lookupSubscription = null;
      let valueChangedSubscription = this.orgName.valueChanges.subscribe(str =>{
        if(!str || str.length === 0){
          me.orgNames = [];
          me.serviceTypes = [];
          me.serviceType.setValue(null);
        } else {
          if(str && str.length > 2 && !me.orgNameSelected){
            lookupSubscription = me.referralService.lookup(str).subscribe(data=>{
              me.orgNames = data as Array<any>;
            }, (error)=>{
              me.subscriptions.push(lookupSubscription);
            }, ()=>{
              me.subscriptions.push(lookupSubscription);
            });
          }
        }

      }, (error)=>{}, ()=>{});
      me.subscriptions.push(valueChangedSubscription);
    }
  }

  ngOnDestroy() {
    for(let i = 0; i < this.subscriptions.length; i++){
      if(this.subscriptions[i]){
        this.subscriptions[i].unsubscribe();
      }

    }
  }
}

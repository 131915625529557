import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CaseNeedService} from "./need.service";
import {Subscription} from "rxjs";
import {icons, LOGIN_PATH} from "../../../model/app.constants";
import {AuthenticationService} from "../../login/authentication.service";
import {Router} from "@angular/router";
import {Beneficiary, CaseNeed, HrsnType, StatusCode} from "../../../model/app.model";
import {MatDialog, MatDialogRef} from "@angular/material";
import {NeedAddupdateComponent} from "./need.addupdate.component";
import {getIcon} from "../../../helpers/common.function";


@Component({
  selector: 'app-case-needs',
  templateUrl: './need.component.html',
  providers : [CaseNeedService]
})
export class NeedComponent implements OnInit, OnDestroy {
  private subscription : Subscription;
  @Input() caseId : string;
  @Input() beneficiary : Beneficiary;
  protected needs : Array<CaseNeed> = new Array<CaseNeed>();
  protected dialogRef : MatDialogRef<any, any> = null;
  constructor(private needService : CaseNeedService, private authService : AuthenticationService, private router : Router, private dialog: MatDialog) { }

  protected fetchNeedsByCaseId(){
    const me = this;
    this.subscription = this.needService.getCaseNeedsByCase(this.caseId).subscribe(data => {
      me.needs = data['body'];
    }, error1 => {

    });
  }

  protected getHsrn(hsrn : HrsnType) : string {
    return HrsnType[hsrn];
  }

  protected getStatus(status : StatusCode) : string {
    return StatusCode[status];
  }

  protected addNeed() {
    const me = this;
    let data = {isUpdate : false, need : null, name : me.beneficiary.firstName, caseId : this.caseId, needs : this.needs};
    this.dialogRef = this.dialog.open(NeedAddupdateComponent, { height: 'auto', width: '75%', data: data});
    this.dialogRef.afterClosed().subscribe(result => {
      if(result && result.isSaved){
        me.needs.push(result.need);
      }
    });
  }

  protected updateNeed(need : CaseNeed){
    const me = this;
    let data = {isUpdate : true, need : need, name : me.beneficiary.firstName, caseId: this.caseId};
    this.dialogRef = this.dialog.open(NeedAddupdateComponent, { height: 'auto', width: '75%', data: data});
    this.dialogRef.afterClosed().subscribe(result => {
      if(result && result.isSaved){
        let savedNeed = result.need;
        for(let i = 0; i < this.needs.length; i++){
          let aNeed = this.needs[i];
          if(aNeed.id === savedNeed.id){
            this.needs[i] = savedNeed;
            break;
          }
        }
      }
    });
  }

  public getIcon(label) : string {
    return getIcon(label);
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.router.navigate([LOGIN_PATH]).then();
    } else {
      this.fetchNeedsByCaseId();
    }
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ScreeningQuestion, ScreeningResponse} from "../../../../../model/app.model";


@Component({
  selector: 'screening-response',
  templateUrl: './response.component.html'
})

export class ResponseComponent implements OnInit{

  @Input() parent:ScreeningQuestion;
  @Input() response: ScreeningResponse;
  @Output() responseSelected : EventEmitter<ScreeningResponse> = new EventEmitter<ScreeningResponse>();

  constructor(){
  }

  public onResponseSelected() : void {
    this.response.selected = !this.response.selected;
    this.parent.answered = true;
    this.responseSelected.emit(this.response);
  }

  onFieldFocus($event){
    this.response.selected = true;
    this.parent.answered = true;
    this.responseSelected.emit(this.response);
  }

  ngOnInit() {

  }
}

import {Component} from "@angular/core";
import {NavigatorService} from "./navigator.service";
import {AuthenticationService} from "../login/authentication.service";
import {Router} from "@angular/router";
import {Beneficiary, Navigator} from "../../model/app.model";
import {BaseComponent} from "../common/base.component";
import {first} from "rxjs/operators";
import {MatTableDataSource} from "@angular/material";
import {BeneficiaryService} from "../beneficiary/beneficiary.service";
import {BENEFICIARY_PATH} from "../../model/app.constants";
import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";


@Component({
  selector : 'app-navigator-list',
  templateUrl : './navigator.list.component.html',
  providers : [NavigatorService]
})

export class NavigatorListComponent extends BaseComponent{

  hasError : boolean = false;
  errorMessage : string;
  navigators = {};
  keyList:string[] = []; //right side column array. This will be spliced so that we can display two columns
  leftKeyList:string[] = [];
  constructor(private navigatorService : NavigatorService, protected authService : AuthenticationService, protected router : Router, private beneDataService : BeneficiaryDataService){
    super(router, authService);

  }

  public gotoBenePage(beneId:string, event:any){
    event.preventDefault();
    let beneficiary = new Beneficiary();
    beneficiary.id = beneId;
    this.beneDataService.reset();
    this.beneDataService.setBeneficiary(beneficiary);
    this.router.navigate([BENEFICIARY_PATH]).then();
  }

  public rowTracking(index, item){
    return index;
  }

  public toggle(event){
    console.log(event);
  }
  private getName(firstName:string, middleName:string, lastName:string){
    return (firstName + (middleName?middleName+' ':' ') + lastName);
  }

  private fetchNavigators(){
    let me = this;
    let subscription = this.navigatorService.fetchNavigators().subscribe(data=>{
      let tempArray = data['body'] as Array<Navigator>;
      if(tempArray){
        tempArray.forEach(navigator => {
          //group navigators by their unique userId
          //let name = me.getName(navigator.firstName, navigator.middleName, navigator.lastName);
          let name = navigator['userId'];
          if(name in me.navigators){
            me.navigators[name].push(navigator);
          } else {
            let array = new Array<Navigator>();
            array.push(navigator);
            me.navigators[name] = array;
            me.keyList.push(name);
          }
        });
      }
    }, error1 => {
      this.hasError = true;
      this.errorMessage = error1['error'];
    },()=>{
      subscription.unsubscribe();
      //divide the list in two to display in two columns
      let half = Math.ceil(me.keyList.length/2);
      me.leftKeyList = me.keyList.splice(0, half);
    });
  }
  protected init() {
    super.init();
    this.fetchNavigators();
  }

}

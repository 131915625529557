import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs/internal/Subscription";
import {BeneficiaryScreening, Screening, Site} from "../../model/app.model";
import {first, map} from "rxjs/operators";
import {Router} from "@angular/router";
import {ScreeningDataService} from "../../helpers/screening.dataservice";
import {
  LOGIN_PATH,
  SEARCH_BENEFICIARY_HOME, SITE_PATH
} from "../../model/app.constants";
import {ScreeningService} from "../../helpers/screening.service";
import {AuthenticationService} from "../login/authentication.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {isValueEmpty} from "../../helpers/common.validator";
import {combineLatest} from "rxjs";

@Component({
  selector: 'screening-consent',
  templateUrl: './consent.component.html',
  providers: [ScreeningService],
  styleUrls: []
})
export class ConsentComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  dataSubscription: Subscription;
  screening : BeneficiaryScreening;
  consent : boolean = false;
  hasError : boolean = false;
  error : string = '';
  site : Site;
  submitted : boolean = false;
  consentForm : FormGroup;
  disableSubmit : boolean = true;
  constructor(private screeningService : ScreeningService, private router : Router,
              public dataService : ScreeningDataService, private authService : AuthenticationService, private formBuilder: FormBuilder) {
      this.consentForm = this.formBuilder.group({month: new FormControl('', [Validators.required, Validators.maxLength(2), Validators.max(12), Validators.min(1)]),
        day: new FormControl('', [Validators.required, Validators.maxLength(2), Validators.max(31), Validators.min(1)] ),
        year: new FormControl('', [Validators.required, Validators.maxLength(4), Validators.min(1900)])});
  }

  public respondYes(){
    this.consent = true;
    if(this.isValid()){
      this.saveScreening();
    }
  }

  public respondNo(){
    this.consent = false;
    this.saveScreening();
  }

  isValid(){
    if(this.day.invalid){
      return false;
    }
    if(this.month.invalid){
      return false;
    }
    if(this.year.invalid){
      return false;
    }
    return true;
  }

  private saveScreening (){
    this.reset();
    this.submitted = true;
    this.screening.consented = this.consent;
    this.screening.site = this.site;
    let data = this.consentForm.value;
    let date = new Date(data.year, data.month-1, data.day).getTime().toString();
    this.screening.dateCreated = date;
    if(this.consent){
      this.screeningService.saveScreening(this.screening).subscribe(data => {
        let benScreening = data['body'];
        this.screening = benScreening;
        this.dataService.setBeneficiaryScreening(benScreening);
        this.router.navigate([SEARCH_BENEFICIARY_HOME]).then();
      });
    } else {
      this.subscription = this.screeningService.saveScreening(this.screening).pipe(first()).subscribe(resp => {
        if(resp){
          let newScreening = resp['body'];
          this.dataService.setBeneficiaryScreening(newScreening);
          this.router.navigate([SITE_PATH]).then();
        }

      }, error1 => {
        this.hasError = true;
        this.error = error1;
        if(error1['status'] === 401){
          this.router.navigate([LOGIN_PATH]).then();
        }
      })
    }

  }

  onKey(event){
    let control = this.consentForm.get(event.target.id);
    if(control){
      control.setErrors(null);
    }
    this.shouldDisableSubmit()
  }

  shouldDisableSubmit(){
    if(!isValueEmpty(this.day.value) && !isValueEmpty(this.month.value) && !isValueEmpty(this.year.value) && (this.year.value.length === 4)){
      this.disableSubmit = false;
    } else {
      this.disableSubmit = true;
    }
  }

  get day() { return this.consentForm.get('day');}
  get month() { return this.consentForm.get('month');}
  get year() { return this.consentForm.get('year');}

  private reset(){
    this.hasError = false;
    this.error = '';
    this.screening = new BeneficiaryScreening();
    this.submitted = false;

  }
  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.router.navigate([LOGIN_PATH]).then();
    } else {
      this.site = this.dataService.getSite();
    }
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
    if(this.dataSubscription){
      this.dataSubscription.unsubscribe();
    }
  }

}

import {Injectable} from "@angular/core";
import {CanActivate, CanActivateChild, CanDeactivate, Router} from "@angular/router";
import {AuthenticationService} from "./authentication.service";
import {LOGIN_PATH} from "../../model/app.constants";

@Injectable()
export class Authguard implements CanActivate, CanActivateChild {
  constructor(private authService:AuthenticationService, private router : Router){
  }

  canActivate(){
    return this.checkActivation();

  }

  canActivateChild() {
    return this.checkActivation();
  }

  checkActivation(){
    if (!this.authService.isLoggedIn()){
      this.router.navigate([LOGIN_PATH]);
      return false;
    }
    return true;
  }


}

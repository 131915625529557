import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {HrsnType, Referral} from "../../../model/app.model";
import {isValueEmpty} from "../../../helpers/common.validator";
import {ReferralService} from "./referral.service";
import {MatDialog, MatDialogRef} from "@angular/material";
import {AddUpdateReferral} from "./referral.addupdate";
import {getIcon} from "../../../helpers/common.function";

//main referral component displayed in a tab - add/update referral modal can be launch from here.
@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  providers : [ReferralService]
})
export class ReferralComponent implements OnInit, OnDestroy {

  subscription : Subscription;
  hasError : boolean = false;
  errorMessage : string = null;
  referrals : Array<Referral>;
  dialogRef : MatDialogRef<any, any>;
  selectedReferral : Referral;
  hrsnType : HrsnType;

  @Input() beneId : string;

  constructor(private referralService : ReferralService, private dialog : MatDialog) {
    this.referrals = new Array<Referral>();
  }

  public addReferral(){
    let me = this;
    let data = {"isUpdate" : false, "beneId" : this.beneId};
    this.dialogRef = this.dialog.open(AddUpdateReferral, { height: 'auto', width: '75%', data: data});
    let sub = this.dialogRef.afterClosed().subscribe(result =>{
      if(result && result['referral']){
        me.referrals.unshift(result['referral']);
      }
    }, error1 => {}, ()=>{
      sub.unsubscribe();
    });

  }

  editReferral(referral){
    this.selectedReferral = referral;
    let data = {"isUpdate" : true, "beneId" : this.beneId, "referral" : this.selectedReferral};
    this.dialogRef = this.dialog.open(AddUpdateReferral, { height: 'auto', width: '75%', data: data});
    let sub = this.dialogRef.afterClosed().subscribe(result =>{
      if(result && result['referral']){
        let referral = result['referral'];
        for(let i = 0; i < this.referrals.length; i++){
            if(this.referrals[i].id === referral.id){
              this.referrals[i] = referral;
            }
        }
      }
    }, error1 => {}, ()=>{
      sub.unsubscribe();
    });
  }

  protected getNeedLabel(need : HrsnType):string{
    return HrsnType[need];
  }

  public getIcon(label) : string {
    return getIcon(label);
  }

  ngOnInit() {
    if(!isValueEmpty(this.beneId)){
      let me = this;
      me.subscription = this.referralService.fetchReferrals(this.beneId).subscribe(data=>{
        if(data){
          let tempArray = data['body'] as Array<Referral>;
          if(tempArray && tempArray.length > 0){
            me.referrals = tempArray.sort((aReferral, bReferral)=>{
              return aReferral.openDate > bReferral.openDate ? -1 : 0;
            });
          }

        }
      }, error1 => {
        if(error1.status != 404){
          //not found. beneficiary doesn't have any referrals. shouldn't treat as error in this case.
          me.hasError = true;
          me.errorMessage = error1['error'];
        }

      });
    }
  }

  ngOnDestroy() {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}

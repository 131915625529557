import {HttpErrorResponse} from "@angular/common/http";
import {GENERAL_ERROR} from "../model/app.constants";
import {throwError} from "rxjs/internal/observable/throwError";
import {ErrorHandler, Injectable} from "@angular/core";

@Injectable()
export class AppErrorHandler implements ErrorHandler{
  constructor(){
  }

  public handleError(error: HttpErrorResponse) {
    if(error.status === 401) {
      return throwError(error);
    } if(error.status === 403){
      return throwError(
        GENERAL_ERROR);
    }
    if(error.status === 0){
      //connection to server not available
      return throwError(GENERAL_ERROR);
    }
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError('Backend returned code ${error.status}, ' +
        'body was: ${error.error}');
    }
  };
}

import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {NavigatorService} from "./navigator.service";
import {UserService} from "../user/user.service";
import {Beneficiary, Navigator, Person, Status} from "../../model/app.model";
import {DialogSystemManager} from "../../system/dialog.system.manager";
import {BaseComponent} from "../common/base.component";
import {AuthenticationService} from "../login/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector : 'app-add-navigator',
  templateUrl : './add.navigator.component.html',
  providers : [NavigatorService, UserService]
})
export class AddupdateNavigatorComponent extends BaseComponent{
  beneficiary : Beneficiary;
  navigators : any[] = [];
  statuses : Status[] = [];
  selectedNavigator : Navigator = null;
  selectedStatus : Status = null;
  hasError : boolean;
  errorMessage : string;
  isUpdate : boolean = false;
  title : string;
  saved : boolean = false;
  constructor(public dialogRef : MatDialogRef<AddupdateNavigatorComponent>, private navigatorService : NavigatorService, private userService : UserService,
              @Inject(MAT_DIALOG_DATA) public data : any, private dialogManager : DialogSystemManager, protected router : Router, protected authService : AuthenticationService){
    super(router, authService);
    this.dialogManager.register('AddupdateNavigatorComponent', this.dialogRef);
    this.beneficiary = data['beneficiary'];
    this.isUpdate = data['isUpdate'];
    if(this.isUpdate){
      this.title = 'Update Navigator Assignment';
      this.selectedNavigator = data['navigator'];
      this.navigators.push(this.selectedNavigator);
      if(this.selectedNavigator.status === 'ACTIVE'){
        this.statuses.push(Status.INACTVIE);
        this.selectedStatus = Status.INACTVIE;
      }else{
        this.statuses.push(Status.ACTIVE);
        this.selectedStatus = Status.ACTIVE;
      }
    } else {
      this.title = 'Add Navigator Assignment';
      this.statuses.push(Status.ACTIVE);
      this.selectedStatus = Status.ACTIVE;
    }
  }

  public onNavigatorSelected(event){
    this.selectedNavigator = event.value;
  }
  public onStatusSelected(event){
    this.selectedStatus = event.value;
  }

  private valid () : boolean {
    if(!this.selectedNavigator){
      this.hasError = true;
      this.errorMessage = "Please select a navigator!";
      return false;
    }
    return true;
  }
  public save(){
    let me = this;
    let result = {"navigator" : null, "saved" : false};
    this.hasError = false;
    this.errorMessage = null;
    if(this.isUpdate){
      let sub = this.navigatorService.updateNavigator(this.selectedNavigator.id, this.selectedStatus).subscribe(data=>{
        let updatedNav = data['body'];
        if(updatedNav){
          me.saved = true;
          result.navigator = updatedNav;
          result.saved = me.saved;
        }
      }, error1 => {
        me.hasError = true;
        me.errorMessage = error1['error']['message']?error1['error']['message']:error1['error'];
      }, ()=>{
        sub.unsubscribe();
        me.dialogRef.close(result);
      });
    }else{
      if(this.valid()){
        let subscription = this.navigatorService.attachNavigator(this.selectedNavigator.id, this.beneficiary.id).subscribe(data => {
          me.saved = true;
          result.navigator = data['body'];
          result.saved = me.saved;
        }, error1 => {
          me.hasError = true;
          me.errorMessage = error1['error']['message']?error1['error']['message']:error1['error'];
        }, ()=>{
          subscription.unsubscribe();
          me.dialogRef.close(result);
        });
      }
    }
  }
  protected init(){
    if(!this.isUpdate){
      let me = this;
      let subscription = this.userService.retrieveNavigators().subscribe(data => {
        me.navigators = data['body'];
      }, error1 => {

      }, ()=>{
        subscription.unsubscribe();
      });
    }
  }
}

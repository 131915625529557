import {Component, Input} from "@angular/core";
import {BeneficiaryScreening} from "../../model/app.model";
import {MatDialog, MatDialogRef} from "@angular/material";
import {ScreeningDetailsComponent} from "./details.screening";
import {SITE_PATH} from "../../model/app.constants";
import {Router} from "@angular/router";


@Component({

  templateUrl : './view.screening.html',
  selector : 'app-view-screening'
})
export class ViewScreeningComponent {

  @Input() screenings : BeneficiaryScreening[];
  protected dialogRef : MatDialogRef<any, any> = null;

  constructor(private dialog : MatDialog, private router : Router){}

  public viewScreening(screening : BeneficiaryScreening){
    let data = {"beneficiaryScreening" : screening};
    this.dialogRef = this.dialog.open(ScreeningDetailsComponent, { height: 'auto', width: '80%', data: data});
  }
  public startNewScreening() : void {
    this.router.navigate([SITE_PATH]).then();
  }
}

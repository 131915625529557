import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from "../login/authentication.service";
import {Router} from "@angular/router";
import {LOGIN_PATH, NAVIGATION_LIST, SEARCH_BENEFICIARY_HOME, USER_MANAGEMENT} from "../../model/app.constants";
import {SystemManager} from "../../system/system.manager";
import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";
import {Beneficiary} from "../../model/app.model";
import {DialogSystemManager} from "../../system/dialog.system.manager";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: []
})
export class NavComponent implements OnInit, OnDestroy {

  constructor(private AuthService : AuthenticationService, private router : Router,
              private systemManager : SystemManager, private beneDataService : BeneficiaryDataService, private dialoManager : DialogSystemManager) {

  }

  logout(){
    if(this.AuthService.logout()){
      //close and clear any dialogs that may be open
      this.dialoManager.closeAll();
      this.router.navigate([LOGIN_PATH]).then();
    }

  }

  gotoPatientLookup(){
    this.beneDataService.setBeneficiary(null);
    this.beneDataService.setSearchResultBeneficiaries(new Array<Beneficiary>());
    this.router.navigate([SEARCH_BENEFICIARY_HOME]).then();
  }
  gotoUserManagement(){
    this.beneDataService.setBeneficiary(null);
    this.beneDataService.setSearchResultBeneficiaries(new Array<Beneficiary>());
    this.router.navigate([USER_MANAGEMENT]).then();
  }
  gotoNavigationList(){
    this.router.navigate([NAVIGATION_LIST]).then();
  }
  ngOnInit() {
  }
  ngOnDestroy() {
  }

}

import {Injectable} from "@angular/core";
import {MatDialogRef} from "@angular/material";

@Injectable()

export class DialogSystemManager {
  protected dialogs : Map<string, MatDialogRef<any>>;
  constructor(){
    this.dialogs = new Map<string, MatDialogRef<any>>();
  }

  public register(name: string, dialog : MatDialogRef<any>){
    if(this.dialogs){
      this.dialogs[name] = dialog;
    }
  }
  public closeAll(){
    if(this.dialogs){
      for(let key in this.dialogs){
        this.dialogs[key].close();
      }
      this.dialogs.clear();
    }
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {
  SITE_PATH,
  LOGIN_PATH,
  SEARCH_BENEFICIARY_HOME, SCREENING_PATH,
} from "../../model/app.constants";
import {AuthenticationService} from "../login/authentication.service";
import {Site} from "../../model/app.model";
import {SiteService} from "../../helpers/site.service";
import {Subscription} from "rxjs/internal/Subscription";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ScreeningDataService} from "../../helpers/screening.dataservice";
import {SystemManager} from "../../system/system.manager";
import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: []
})
export class HomeComponent implements OnInit, OnDestroy {

  sites : Site[];
  subscription: Subscription;
  submitted : boolean;
  hasError : boolean;
  siteForm : FormGroup;
  selectedSite : Site;
  constructor(private authService: AuthenticationService, private router: Router, private siteService : SiteService, private dataService : ScreeningDataService, private sysManager : SystemManager,
              private formBuilder: FormBuilder, private beneDataService : BeneficiaryDataService) {
    this.reset();
    this.siteForm = formBuilder.group({ screeningSite : new FormControl('', Validators.required)});
    this.selectedSite = new Site();
  }

  private reset() : void {
    this.submitted = false;
    this.hasError = false;
  }

  next(){
    this.reset();
    this.submitted = true;
    if(this.isValid()){
      if(this.authService.isLoggedIn()){
        this.router.navigate([SCREENING_PATH]).then();
      } else {
        this.router.navigate([LOGIN_PATH]).then();
      }
    }

  }

  onChange(event){
    this.selectedSite = this.siteForm.value['screeningSite'];
    this.dataService.setSite(this.selectedSite);
  }

  isValid () : boolean {
    if (this.screeningSite.invalid){
      return false;
    }
    return true;
  }
  get screeningSite() { return this.siteForm.get('screeningSite');}

  ngOnInit() {
    let me = this;
    this.reset();
    if(!this.authService.isLoggedIn()){
      this.router.navigate([LOGIN_PATH]).then();
    } else {
      this.siteService.fetchSites();
      this.subscription = this.siteService.siteData.subscribe(data => {
        me.sites = data as Array<Site>;
      });
    }
  }

  ngOnDestroy() {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}

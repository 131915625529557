import {AbstractControl, Validator, ValidatorFn} from '@angular/forms';
export function isEmpty(control: AbstractControl) {
  return isValueEmpty(control.value);
}

export function isValueEmpty(value: string) {

  if (value === null) {
    return true;
  }
  if (value === undefined) {
    return true;
  }
  value = value.toString();
  if (value === "") {
    return true;
  }
  if (value.replace(/\s/g, "").length === 0) {
    return true;
  }
  return false;
}

export function checkLimit(min : number, max: number, message : string) : ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if(control.value < min){
      return {"message" : message};
    }
    if(control.value > max){
      return {"message" : message};
    }
    return null;
  };

}
export function checkMaxLength(len : number) : ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if(control.value.toString().length > len){
      return {"message" : name + " character length cannot exceed " + len};
    }
    return null;
  };

}
export function isNumber(value : string):boolean {
  let valid = true;
  if(isValueEmpty(value)){
    valid = false;
  }
  let areNumbers = /^\d+$/.test(value);
  if(!areNumbers){
    valid = false;
  }
  return valid;
}

import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {CaseNeedService} from "./need.service";
import {CaseNeed, EncounterType, HrsnType, ResolutionType, StatusCode} from "../../../model/app.model";
import {Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {isEmpty, isValueEmpty} from "../../../helpers/common.validator";
import {DialogSystemManager} from "../../../system/dialog.system.manager";

@Component({
  selector: 'addupdate-need',
  templateUrl: './add.need.html',
  providers : [CaseNeedService]
})

export class NeedAddupdateComponent implements OnInit, OnDestroy{
  protected need : CaseNeed = new CaseNeed();
  private subscription : Subscription;
  protected needForm : FormGroup;
  protected submitted : boolean = false;
  protected hrsnTypes;
  protected resolutionTypes;
  protected encounterTypes = [];
  protected statusCodes;
  protected isUpdate : boolean;
  protected firstName : string;
  protected caseId : string;
  protected hasError : boolean = false;
  protected error : string;
  protected title : string;
  protected existingNeeds : Array<CaseNeed>;
  constructor(private needService : CaseNeedService, protected formBuilder: FormBuilder, public dialogRef : MatDialogRef<NeedAddupdateComponent>, @Inject(MAT_DIALOG_DATA) public data : any,
              private dialogManager : DialogSystemManager){
    this.dialogManager.register('NeedAddupdateComponent', this.dialogRef);
    if(data.isUpdate){
      this.need = data.need;
      this.isUpdate = true;
      this.title = 'Update Need to ' + data.name + '\'s Navigation Case';
    } else {
      this.need = new CaseNeed();
      this.isUpdate = false;
      this.title = 'Add Need to ' + data.name + '\'s Navigation Case';
      this.existingNeeds = data.needs;
    }
    this.firstName = data.name;
    this.caseId = data.caseId;
    this.hrsnTypes = Object.keys(HrsnType).sort();
    this.resolutionTypes = Object.keys(ResolutionType).sort();
    let tempEncounters = Object.keys(EncounterType).sort();
    if(this.isUpdate){
      for(let i = 0; i < tempEncounters.length; i++){
        let encounter = tempEncounters[i];
        if(EncounterType[encounter] === EncounterType.FOLLOW_UP){
          this.encounterTypes.push(encounter);
          break;
        }
      }
    } else {
      this.encounterTypes = Object.keys(EncounterType).sort();
    }
    this.statusCodes = Object.keys(StatusCode).sort();
    this.needForm = this.formBuilder.group({hrsnType : new FormControl(this.need.hrsnType, [Validators.required]),
      encounterType : new FormControl(this.isUpdate?this.encounterTypes[0]:this.need.encounterType, [Validators.required]),
      resolutionType : new FormControl(this.need.resolutionType, [Validators.required]), statusCode : new FormControl(this.need.statusCode, [Validators.required])});
  }

  private createNeed() {
    let data = this.needForm.value;
    let newNeed = JSON.parse(JSON.stringify(this.need));
    if(!this.isUpdate){
      newNeed.caseId = this.caseId;
      newNeed.hrsnType = data.hrsnType;
    } else {
      //need control is disabled - set value
      newNeed.hrsnType = this.hrsnType.value;
    }
    newNeed.encounterType = data.encounterType;
    newNeed.resolutionType = data.resolutionType;
    newNeed.statusCode = data.statusCode;
    this.need = newNeed;
  }

  protected updateResolutionTypes(){
    let statusCode = this.statusCode.value;
    let types = Object.keys(ResolutionType).sort();
    this.resolutionTypes = [];
    if(!isValueEmpty(statusCode)){
      this.resolutionType.enable();
      if(StatusCode[statusCode] === StatusCode.RESOLVED){
        for(let i = 0; i < types.length; i++){
          let type = types[i];
          if(type === 'RESOLVED' || type === 'SUCCESSFUL'){
            this.resolutionTypes.push(type);
          }
        }
      } else if (StatusCode[statusCode] === StatusCode.IN_PROGRESS){
        for(let i = 0; i < types.length; i++){
          let type = types[i];
          if(type === 'RESOLUTION_PENDING'){
            this.resolutionTypes.push(type);
          }
        }
      } else if(StatusCode[statusCode] === StatusCode.UNRESOLVED){
        for(let i = 0; i < types.length; i++){
          let type = types[i];
          if(type === 'UNAVAILABLE' || type === 'ATTEMPT_FAILED' || type === 'OPTOUT'){
            this.resolutionTypes.push(type);
          }
        }
      }
    } else {
      this.resolutionType.disable();
    }
  }

  protected getHrsnLabel(hsrn : HrsnType){
    return HrsnType[hsrn];
  }
  protected getResolutionType(res : ResolutionType){
    return ResolutionType[res];
  }
  protected getEncounterType(encounter : EncounterType){
    return EncounterType[encounter];
  }
  protected getStatusCode(status : StatusCode){
    return StatusCode[status];
  }

  protected validated(){
    let valid = true;
    this.submitted = false;
    if(isEmpty(this.hrsnType)){
      this.hrsnType.setErrors({'message' : "Social need type is required"});
      valid = false;
    }
    if(isEmpty(this.encounterType)){
      this.encounterType.setErrors({'message' : "Encounter type is required"});
      valid = false;
    }
    if(isEmpty(this.resolutionType)){
      this.resolutionType.setErrors({'message' : "Resolution type is required"});
      valid = false;
    }
    if(isEmpty(this.statusCode)){
      this.statusCode.setErrors({'message' : "Status code is required"});
      valid = false;
    }
    if(this.existingNeeds && this.existingNeeds.length > 0){
      this.error = '';
      this.hasError = false;
      for(let i = 0; i < this.existingNeeds.length; i++){
        let existingNeed = this.existingNeeds[i];
        if (this.hrsnType.value == existingNeed.hrsnType.toString()){
          valid = false;
          this.hasError = true;
          this.error = "This Need already exists on the Navigation Case. Please edit the existing Need or select a new Need to be added to the Navigation Case";
          break;
        }
      }
    }
    return valid;
  }

  protected close(){
    this.dialogRef.close({isSaved:false});
  }

  private reset(){
    this.submitted = true;
    this.hasError = false;
  }
  protected save(){
    this.reset();
    if(this.validated()){
      if(this.isUpdate){
        this.updateNeed();
      } else {
        this.addNeed();
      }
    }
  }

  protected addNeed() {
    this.createNeed();
    const me = this;
    let savedData = {isSaved : false};
    this.subscription = this.needService.submitNewNeed(this.need).subscribe(data =>{
      me.need = data;
      savedData['need'] = me.need;
      savedData.isSaved = true;
    },  (error:any) => {
      me.hasError = true;
      me.error = error.error;
    }, () => {
      if(!me.hasError){
        me.dialogRef.close(savedData);
      }
    });
  }

  protected updateNeed() {
    const me = this;
    this.createNeed();
    let savedData = {isSaved : false};
    this.subscription = this.needService.updateNeed(this.need).subscribe(data =>{
      me.need = data;
      savedData.isSaved = true;
      savedData['need'] = me.need;
    }, (error:any) => {
      me.hasError = true;
      me.error = error.error;
    }, () => {
      if(!me.hasError){
        me.dialogRef.close(savedData);
      }
    });
  }

  get hrsnType(){
    return this.needForm.get('hrsnType');
  }

  get encounterType(){
    return this.needForm.get('encounterType');
  }

  get resolutionType(){
    return this.needForm.get('resolutionType');
  }

  get statusCode(){
    return this.needForm.get('statusCode');
  }

  ngOnInit() {
    this.updateResolutionTypes();
    if(this.isUpdate){
      this.hrsnType.disable();
      this.encounterType.disable();
    }
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}

import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserService} from "./user.service";
import {Authority, User, UserStatus} from "../../model/app.model";
import {MatDialog, MatDialogRef, MatSort, MatTable, MatTableDataSource} from "@angular/material";
import {ManageUserComponent} from "./user.manage";
import {Subscription} from "rxjs";
import {ConfirmDialog} from "../common/confirm.dialog";
import {LOGIN_PATH} from "../../model/app.constants";
import {AuthenticationService} from "../login/authentication.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-user',
  templateUrl: './user.main.html',
  providers : [UserService]
})
export class UserComponent implements OnInit, OnDestroy {
  displayColumns : string [] = ['lastName', 'firstName', 'email', 'role', 'site', 'status', 'action'];
  users : Array<User> = new Array<User>();
  userDataSource : MatTableDataSource<User>;
  subscription : Subscription;
  protected dialogRef : MatDialogRef<any, any> = null;
  hasError : boolean = false;
  errorMessage : string;
  @ViewChild(MatTable, {static : false}) usersTable : MatTable<any>;
  @ViewChild(MatSort, {static : false}) sort: MatSort;

  constructor(private userService : UserService, private dialog: MatDialog, private authService : AuthenticationService, private router : Router) {
    this.fetchUsers();
  }

  protected addUser(){
    let me = this;
    this.dialogRef = this.dialog.open(ManageUserComponent, { height: 'auto', width: '75%', data: {isUpdate : false}});
    this.dialogRef.afterClosed().subscribe(data =>{
      if(data && data['saved']){
        me.users.push(data['user']);
        me.userDataSource.sort = me.sort;
        me.usersTable.renderRows();
      }
    });
  }

  protected uninviteUser(user){
    this.dialogRef = this.dialog.open(ConfirmDialog, {height : 'auto', width : '50%', data : {'message' : 'Are you sure you want to uninvite this user?'}});
    this.dialogRef.afterClosed().subscribe(confirm=>{
      if(confirm){
        let me = this;
        let sub = this.userService.uninviteUser(user.id).subscribe(data=>{

        }, error1 => {
          me.hasError = true;
          me.errorMessage = 'Failed to univite user';
        }, ()=>{
          sub.unsubscribe();
          me.hasError = false;
          me.errorMessage = null;
          for(let i = 0; i < me.users.length; i++){
            if(user.id === me.users[i].id){
              me.users.splice(i, 1);
              break;
            }
          }
          me.usersTable.renderRows();
        });
      }
    });

  }

  protected reinviteUser(user){
    this.dialogRef = this.dialog.open(ConfirmDialog, {height : 'auto', width : '50%', data : {'message' : 'Are you sure you want to resend an invite to this user?'}});
    this.dialogRef.afterClosed().subscribe(confirm=>{
      if(confirm){
        let me = this;
        let sub = this.userService.reinviteUser(user.id).subscribe(data=>{

        }, error1 => {
          me.hasError = true;
          me.errorMessage = 'Failed to re-invite user';
        }, ()=>{
          sub.unsubscribe();
          me.hasError = false;
          me.errorMessage = null;
        });
      }
    });

  }

  protected updateUser(user){
    let me = this;
    this.dialogRef = this.dialog.open(ManageUserComponent, { height: 'auto', width: '75%', data: {isUpdate : true, "user" : user}});
    this.dialogRef.afterClosed().subscribe(data =>{
      if(data && data['saved']){
        let theUser = data['user'] as User;
        if(theUser) {
          for (let i = 0; i < me.users.length; i++) {
            if (me.users[i].id === theUser.id) {
              me.users[i] = theUser;
              me.userDataSource.sort = me.sort;
              me.usersTable.renderRows();
            }
          }
        }
      } else if(data && data['deleted']){
        let theUser = data['user'] as User;
        if(theUser) {
          for (let i = 0; i < me.users.length; i++) {
            if (me.users[i].id === theUser.id) {
              me.users.splice(i, 1);
              me.usersTable.renderRows();
            }
          }
        }
      }
    });
  }

  protected isDisabled(user : User){
    if(user.authorities[0].authority.toString() === 'SYSADMIN' || user.authorities[0].authority.toString() === 'REPORTUSER'){
      return true;
    }
    return false;
  }

  protected getAuthorities(authorities : Array<Authority>){
    let array = new Array<string>();
    if(authorities && authorities.length > 0){
      for(let i = 0; i < authorities.length; i++){
        array.push(authorities[i].authority);
      }
    }
    return array;
  }

  private fetchUsers(){
    let me = this;
    me.subscription = me.userService.retrieveAllUsers(0, 30).subscribe(response=>{
      let tempUsers = response['body'];
      me.users = tempUsers.sort((aUser, bUser)=>{
        return aUser.lastName <  bUser.lastName ? -1 : (aUser.lastName > bUser.lastName ? 0 : -1);
      }) as Array<User>;
    }, error1 => {
      me.hasError = true;
      me.errorMessage = 'Error occured when fetching user accounts';
    }, () => {
      me.hasError = false;
      me.errorMessage = '';
      me.userDataSource = new MatTableDataSource<User>(me.users);
      setTimeout(()=>{
        me.userDataSource.sort = me.sort;
      });
    });
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.router.navigate([LOGIN_PATH]).then();
    }
  }

  ngOnDestroy(){

  }

}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import {TOKEN} from "../model/app.constants";
import {EncryptionService} from "./encryption.service";
import {UrlService} from "../system/url.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private encryption : EncryptionService, private urlService : UrlService){
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //proceed only if request is made to ahc application backend
    if(request.url.startsWith(this.urlService.appDomain)){
      // add authorization header with jwt token if available
      let encryptedToken = localStorage.getItem(TOKEN);
      let decryptedToken = this.encryption.decrypt(encryptedToken);
      if(decryptedToken){
        let currentUser = JSON.parse(decryptedToken);
        if (currentUser && currentUser.token) {
          request = request.clone({
            setHeaders: {
              Authorization: `${currentUser.token}`
            }
          });
        }
      }
    }
    return next.handle(request);
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ScreeningService} from "../../helpers/screening.service";
import {ScreeningDataService} from "../../helpers/screening.dataservice";
import {Router} from "@angular/router";
import {LOGIN_PATH, SCREENING_PATH, SITE_PATH} from "../../model/app.constants";
import {Beneficiary, BeneficiaryScreening, NavigationCase} from "../../model/app.model";
import {BeneficiaryDataService} from "../../helpers/beneficiary.dataservice";
import {AuthenticationService} from "../login/authentication.service";
import {MatDialog, MatDialogRef} from "@angular/material";
import {UpdateBeneficiaryComponent} from "./update.beneficiary";
import {NavigationCaseService} from "../navcase/navcase.service";
import {BeneficiaryService} from "./beneficiary.service";


@Component({
  selector: 'app-beneficiary',
  templateUrl: './beneficiary.component.html',
  providers: [ScreeningService, NavigationCaseService]
})
export class BeneficiaryComponent implements OnInit, OnDestroy {

  protected hasError : boolean = false;
  protected error : string;
  protected beneficiary : Beneficiary = new Beneficiary();
  protected dialogRef : MatDialogRef<any, any> = null;
  private subscriptions : Array<Subscription> = new Array<Subscription>();
  protected navCase : NavigationCase;
  protected screenings : BeneficiaryScreening[];
  constructor(private screeningService : ScreeningService, private dataService : ScreeningDataService, private benDataservice : BeneficiaryDataService,
              private router : Router, private authService : AuthenticationService, private dialog: MatDialog, private navCaseService : NavigationCaseService,
              private beneService : BeneficiaryService) {

  }

  /*
    Five states of case status:
    (1) Navigation case created, not yet consented
    (2) Patient consents/nav case opened (term dt set to today + 365 days)
    (3) Patient consents/nav case closed (term dt passed) - for now, won't show needs or events on closed case
    (4) Patient does not consent/nav case closed (term dt set to today/no needs display)
    (5) No navigation cases (i.e. patient has not screened high risk)
   */

  //(1) Navigation case created, not yet consented
  public get caseOpenNotTouched() : boolean {
    return this.navCaseService.isCaseOpenNotTouched(this.navCase);
  }
  //(2) Patient consents/nav case opened (term dt set to today + 365 days)
  public get caseOpenConsented() : boolean {
    return this.navCaseService.isCaseOpenConsented(this.navCase);
  }
  //(3) Patient consents/nav case closed (term dt passed)
  public get caseClosedConsented() : boolean {
    return this.navCaseService.isCaseClosedConsented(this.navCase);
  }
  //(4) Patient does not consent/nav case closed
  public get caseClosedAndOptedOut() : boolean {
    return this.navCaseService.isCaseClosedAndOptedOut(this.navCase);
  }

  public get hasNoCase() : boolean {
    if(this.navCase){
      return false;
    }
    return true;
  }

  public decline() {
    this.submitConsent(false);
  }

  public consent() {
    this.submitConsent(true);
  }

  private submitConsent(consent : boolean){
    let me = this;
    let subscription = null;
    subscription = this.navCaseService.submitConsent(this.navCase.id, consent).subscribe(savedCase => {
      me.navCase = savedCase;
    }, error1 => {
      me.hasError = true;
      me.error = error1;
    }, ()=>{this.subscriptions.push(subscription)});
  }

  public startNewScreening() : void {
    this.router.navigate([SITE_PATH]).then();
  }

  public editBeneficiary() {
    let me = this;
    this.dialogRef = this.dialog.open(UpdateBeneficiaryComponent, { height: 'auto', width: '75%', data: {}});
    this.dialogRef.afterClosed().subscribe(result => {
      me.beneficiary = me.benDataservice.getSelectedBeneficiary();
    });
  }

  public hasOpenCase () : boolean {
    return this.navCaseService.isCaseOpen(this.navCase);
  }
  public isCaseConsented() : boolean {
    if(this.navCase){
      return this.navCaseService.isCaseConsented(this.navCase);
    }
    return false;
  }

  private getNavigationCase(){
    let me = this;
    let subscription = null;
    subscription = this.navCaseService.getNavigationCase(this.beneficiary.id).subscribe(response=>{
      me.navCase = response;
    }, error1 => {
      me.hasError = true;
      me.error = error1;
    }, () => {this.subscriptions.push(subscription)});
  }

  private fetchScreenings(){
    let me = this;
    let sub = this.screeningService.fetchScreenings(this.beneficiary.screeningIds).subscribe(data=>{
      let tempScreenings = data['body'] as BeneficiaryScreening[];
      if(tempScreenings){
        me.screenings = tempScreenings.sort((aScreening, bScreening)=>{
          return aScreening.dateCreated < bScreening.dateCreated? 0 : -1;
        }) as BeneficiaryScreening[];
      }
    }, error1 => {}, ()=>{
      sub.unsubscribe();
    })
  }

  ngOnInit() {
    window.scroll(0,0);
    if(!this.authService.isLoggedIn()){
      this.router.navigate([LOGIN_PATH]).then();
    } else {
      let me = this;
      let id = this.benDataservice.getSelectedBeneficiary().id;
      //refetch beneficiary to refresh its data
      let tempSub = this.beneService.getBeneficiary(id).subscribe(data => {
        me.beneficiary = data as Beneficiary;
        this.beneService.updateBeneficiary(me.beneficiary);
      }, error1 => {
        me.hasError = true;
        me.error = error1['error'];
      }, ()=>{
        tempSub.unsubscribe();
        me.getNavigationCase();
        me.fetchScreenings();
      });

    }
  }

  ngOnDestroy() {
    if(this.subscriptions.length > 0){
      for(let i = 0; i < this.subscriptions.length; i++){
        if(this.subscriptions[i]){
          this.subscriptions[i].unsubscribe();
        }
      }
    }
  }

}

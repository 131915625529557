import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {throwError} from "rxjs/internal/observable/throwError";
import {catchError, map} from "rxjs/operators";
import {Beneficiary, BeneficiaryScreening, Screening} from "../model/app.model";
import {AppErrorHandler} from "./app.errorhandler";
import {
  AUTH_HEADER,
  headers,
} from "../model/app.constants";
import {UrlService} from "../system/url.service";

@Injectable()
export class ScreeningService {

  constructor(private systemService : UrlService, private http : HttpClient) {

  }

  public getNewScreening () {
    let NEW_SCREENING_URL = this.systemService.newScreeningUrl;
    return this.http.get(NEW_SCREENING_URL, {headers : headers, observe : 'response'}).pipe(map((res:any) => {
      if(res){
        if(res.status != 200){
          throwError(res.message);
        }
        return res;
      }
    }));
  }
  public saveScreening (screening : BeneficiaryScreening) {
    //console.log(screening);
    let BENEFICIARY_SCREENING_URL = this.systemService.beneficiaryScreeingUrl;
    return this.http.put(BENEFICIARY_SCREENING_URL, JSON.stringify(screening),
      {headers: new HttpHeaders({ 'Content-Type': 'application/json'}).append('Access-Control-Expose-Headers', AUTH_HEADER),
      observe: 'response' }).pipe(map((res:any) => {
        if(res){
          if(res.status != 201 && res.status || 204){
            throwError(res.message);
          }
          return res;
        }
    }));
  }

  public addBeneficiary( screeningId: string, beneficiary : Beneficiary){
    let BENEFICIARY_SCREENING_URL = this.systemService.beneficiaryScreeingUrl;
    let url = BENEFICIARY_SCREENING_URL + "/" + screeningId + "/beneficiaries";
    return this.http.post(url, JSON.stringify(beneficiary), {headers: new HttpHeaders({ 'Content-Type': 'application/json'}).append('Access-Control-Expose-Headers', AUTH_HEADER),
      observe: 'response' }).pipe(map((response:any) => {
        if(response){
          if(response.status != 200){
            throwError(response['message']);
          }
          return response;
        }
    }));
  }

  public submitScreening(screeningId : string, screening : Screening){
    let BENEFICIARY_SCREENING_URL = this.systemService.beneficiaryScreeingUrl;
    let url = BENEFICIARY_SCREENING_URL + "/" + screeningId + "/screenings";
    return this.http.post(url, JSON.stringify(screening), { headers, observe: 'response'}).pipe(map((response:any) => {
      if(response){
        if(response.status != 200){
          throwError(response['message']);
        }
        return response;
      }
    }));
  }

  public updateScreening(screening : BeneficiaryScreening){
    let BENEFICIARY_SCREENING_URL = this.systemService.beneficiaryScreeingUrl;
    return this.http.post(BENEFICIARY_SCREENING_URL, JSON.stringify(screening),
      {headers: new HttpHeaders({ 'Content-Type': 'application/json'}).append('Access-Control-Expose-Headers', AUTH_HEADER),
        observe: 'response' }).pipe(map((res:any) => {
      if(res){
        if(res.status != 200){
          throwError(res.message);
        }
        return res;
      }
    }));
  }

  public fetchScreenings(screenings : string[]){
    let url = this.systemService.beneficiaryScreeingUrl + '/ids?ids=' + screenings.toString();
    return this.http.get(url, {headers: headers, observe: 'response'}).pipe(map((response:any)=>{
      return response;
    }), catchError(error=>{
      throw error;
    }));
  }

}

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UrlService} from "../../system/url.service";
import {Beneficiary} from "../../model/app.model";
import {headers} from "../../model/app.constants";
import {catchError, map} from "rxjs/operators";
import {throwError} from "rxjs";

@Injectable()

export class BeneficiaryService {
  constructor(private http : HttpClient, private systemService : UrlService){}

  public updateBeneficiary(beneficiary : Beneficiary){
    let url = this.systemService.beneficiaryBaseUrl;
    return this.http.post(url, JSON.stringify(beneficiary), {headers : headers, observe : 'response'}).pipe(map((response : any)=>{
      if(response.status != 200){
        throwError(response.statusText);
      } else {
        return response;
      }
    }), catchError((err)=>throwError(err)));
  }
  public deleteBeneficiary(id : string){
    let url = this.systemService.beneficiaryBaseUrl + '/' + id;
    return this.http.delete(url, {headers : headers}).pipe(map((response:any)=>{
      return response;
    }), catchError((err) => throwError(err)));
  }
  public mergeBeneficiary(beneficiary : Beneficiary, deleteBeneId : string){
    let url = this.systemService.mergeBeneficiaryUrl + '?deleteId='+deleteBeneId;
    return this.http.post(url, beneficiary, {headers : headers, observe : 'response'}).pipe(map((response : any) => {
      return response;
    }), catchError((err) => throwError(err)));
  }
  public getBeneficiary(id : string){
    let url = this.systemService.beneficiaryBaseUrl + '/' + id;
    return this.http.get(url, {headers : headers}).pipe(map((response:any)=>{
      return response;
    }), catchError((err) => throwError(err)));
  }
}


import {HttpHeaders} from "@angular/common/http";

export const AUTH_HEADER = 'Authorization';
export const headers = new HttpHeaders( { "Content-Type" : "application/json", "Access-Control-Expose-Headers" : AUTH_HEADER} );
export const COOKIE_HEADER = 'Client_Encryption_Key';
export const LOGIN_PATH = 'login';
export const SITE_PATH = 'site';
export const BENEFICIARY_PATH = 'beneficiary';
export const SCREENING_PATH = 'screening';
export const SCREENING_CONSENT_PATH = 'consent';
export const SCREENING_REGISTRATION_PATH = 'registration';
export const SEARCH_BENEFICIARY_HOME = 'searchhome';
export const SEARCH_BENEFICIARY_PATH = 'search';
export const SEARCH_BENEFICIARY_LIST = 'searchlist';
export const USER_MANAGEMENT = 'user';
export const NAVIGATION_LIST = 'navigations';
export const TOKEN = 'currentToken';
export const SCREENING_RESULT_PATH = 'result';
export const JSON_VIEWER_PATH = 'viewer';
export const LOGIN_FAILURE_MESSAGE = 'Invalid Credentials';
export const GENERAL_ERROR = 'Server is currently not available. Please try again later.';
export const BENEFICIARY_DATA_SERVICE = 'BeneficiaryDataService';
export const SITE_SERVICE = 'SiteService';
export const SCREENING_DATA_SERVICE = 'ScreeningDataService';
export const NEW_USER_PWD = 'user/:new';
export const RESET_USER_PWD = 'user/:reset';
export const RESET_PASSWORD = 'reset';
export const icons = {"Information" : "ion-ios-person", "Living Situation" : "ion-md-bed", "Food" :  "ion-ios-restaurant", "Transportation" : "ion-md-bus",
  "Utilities" : "ion-ios-water", "Safety" : "ion-md-flag", "Financial Strain" : "ion-md-card", "Employment" : "ion-ios-briefcase", "Education" : "ion-md-school",
  "Substance Use" : "ion-md-color-filter", "Mental Health" : "ion-md-happy", "Background" : "ion-md-finger-print", "Family Community Support" : "ion-ios-people",
  "Housing" : "ion-md-bed", "Physical Activity" : "ion-ios-fitness", "Disability" : "ion-ios-glasses", "Financial" : "ion-md-card"};

export const NeedTaxonomyMap = {"LIVING_SITUATION": ['103'], "FOOD" : ['102'], "TRANSPORTATION" : ['105'],
  "UTILITIES" : ['103-03-01', '107-01-04-01', '101-02-05'], "SAFETY" : ['108-07', '108-07-05', '101-06', '108-09-02'],
  "FINANCIAL" : ['107'], "EMPLOYMENT" : ['110'], "SUBSTANCE_USE" : ['106-01'],
  "MENTAL_HEALTH" : ['106-07'], "EDUCATION" : ['109']};


import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import * as crypto from "crypto-js"
import {LOGIN_PATH, TOKEN} from "./model/app.constants";
import {UrlService} from "./system/url.service";
import {SystemManager} from "./system/system.manager";
import {IdleLogoutService} from "./system/idle.logout.service";
import {AuthenticationService} from "./components/login/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {DialogSystemManager} from "./system/dialog.system.manager";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Accountable Health Community';
  subscription : Subscription = null;
  routerSubscription : Subscription = null;
  loginPath : string = '/' + LOGIN_PATH;
 @HostListener('window:beforeunload')
 clear(){
   localStorage.removeItem(TOKEN);
 }
  constructor(private systemManager : SystemManager, private idleService : IdleLogoutService,
              private authService : AuthenticationService, private router: Router, private route : ActivatedRoute, private dialogManager : DialogSystemManager){

    let me = this;
    me.routerSubscription = this.router.events.subscribe((url: any)=>{
        if(me.router.url != me.loginPath && me.router.url != '/' && me.subscription === null){
          me.subscribe();
        }
      }, error1 => {},
      ()=>{

      });

  }

  private subscribe(){
   let me = this;
    me.subscription = this.idleService.logoutTimer.subscribe(data=>{
      if(me.router.url != me.loginPath && me.router.url != '/'){
        if(this.authService.logout()){
          alert("Your session has expired. You will be redirected to login page now");
          this.router.navigate([LOGIN_PATH]).then(()=> {
            this.dialogManager.closeAll();
            if(me.subscription){
              me.subscription.unsubscribe();
              me.subscription = null;
            }
          });
        }
      }
    });
  }

  @HostListener('window:click')
  onClick(){
    this.idleService.reset();
  }
  @HostListener('window:dblclick')
  onDoubleClick(){
    this.idleService.reset();
  }
  @HostListener('window:wheel')
  onWheel(){
    this.idleService.reset();
  }
  @HostListener('window:mousemove')
  onMouseMove(){
    this.idleService.reset();
  }
  @HostListener('window:keydown')
  onKeydown(){
    this.idleService.reset();
  }
  ngOnInit(){

  }
  ngOnDestroy(): void {
   if(this.subscription){
     this.subscription.unsubscribe();
   }
   if(this.routerSubscription){
     this.routerSubscription.unsubscribe();
   }
  }

}

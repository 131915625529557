import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UrlService} from "../../../system/url.service";
import {headers} from "../../../model/app.constants";
import {catchError, map} from "rxjs/operators";
import {CaseEvent} from "../../../model/app.model";


@Injectable()
export class CaseEventService {

  constructor(private http : HttpClient, private systemService : UrlService){}

  public fetchCaseEvents(caseId : string){
    let url = this.systemService.caseEventBaseUrl + '?caseId=' + caseId;
    return this.http.get(url, {headers: headers, observe:'response'}).pipe(map( (response : any )=>{
      return response;
    }), catchError( error => {
      throw error;
    }));
  }
  public addCaseEvent(caseEvent : CaseEvent){
    let url = this.systemService.caseEventBaseUrl;
    return this.http.put(url, caseEvent, {headers: headers, observe:"response"}).pipe(map((response : any)=>{
      return response;
    }), catchError( error => {
      throw error;
    }));
  }
}
